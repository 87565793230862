import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from '@angular/material/tree';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

import { fromEvent } from 'rxjs';
import 'rxjs/add/operator/debounceTime';

import { AuthenticationService } from '../../services/authentication.service';

interface list {
  name: string;
  children?: list[];
  link: string;
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  link: string;
}
@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements AfterViewInit {
  @ViewChild('tree', { static: false }) tree;
  selected = this.location.path();
  loading;
  private _transformer = (node: list, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      link: node.link
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    public location: Location,
    private authenticationService: AuthenticationService,
    private _spinner: NgxSpinnerService
  ) {
    let TREE_DATA: list[] = [];
    const currentUser = this.authenticationService.currentUserValue;
    if (
      currentUser &&
      currentUser.abilities &&
      currentUser.abilities.includes(1)
    ) {
      TREE_DATA.push({
        name: 'Управление привязками',
        link: '/link1',
        children: [
          { name: 'Просмотр и удаление привязок', link: '/link1' },
          { name: 'Привязка к орг. структуре', link: '/link2' },
          { name: 'Привязка к измерениям', link: '/link3' }
        ]
      });
    }
    if (
      currentUser &&
      currentUser.abilities &&
      currentUser.abilities.includes(2)
    ) {
      TREE_DATA.push(
        {
          name: 'Ввод значений показателей',
          link: '/link4'
        },
        {
          name: 'Просмотр истории ввода',
          link: '/link5'
        }
      );
    }

    if (
      currentUser &&
      currentUser.abilities &&
      currentUser.abilities.includes(1)
    ) {
      TREE_DATA.push(
        {
          name: 'Назначение ответственных',
          link: '/link7'
        },
        {
          name: 'Отчет по показателям',
          link: '/link6'
        }
      );
    }

    if (
      currentUser &&
      currentUser.abilities &&
      !currentUser.abilities.includes(1)
    ) {
      TREE_DATA.push(
        {
          name: 'Согласования',
          link: '/user'
        }
      );
    }

    this._spinner.show();
    this.dataSource.data = TREE_DATA;
  }

  ngAfterViewInit() {
    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data.some(item => {
        if (item.children) {
          this.treeControl.expandAll();
        }
      });
    }
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  setSelected(name: string) {
    this.selected = name;
  }

  logout() {
    this.loading = true;
    this.authenticationService.logout();
  }
}
