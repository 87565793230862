export interface column {
  name: string,
  title: string,
  dictionary?: boolean;
  comparator?: any;
}
export interface pageLimitOptions {
  value: number,
  viewValue: string
}
export interface tuList {
  name: string,
  code: string,
  id: number;
  roadCode: number;
}

export interface list {
  name: string;
  children?: list[];
  link: string;
}

export interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  link: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export const MY_FORMATS_CUSTOM = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
