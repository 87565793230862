import { Injectable } from '@angular/core';
import { Decimal } from 'decimal.js';

@Injectable({
  providedIn: 'root'
})
export class BindingInputService {
  constructor() {}

  // начало валидации поля "значение"
  private lastValidInput;

  public keyDown(event) {
    const key = event.key;
    const value = event.target.value.toString().trim();
    const hasDot = value.indexOf('.') !== -1;
    const hasComma = value.indexOf(',') !== -1;
    let lastChanceForMinus = false;

    switch (key) {
      case 'Backspace':
      case 'Clear':
      case 'Delete':
      case 'ArrowLeft':
      case 'ArrowRight':
        return;
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
      case '6':
      case '7':
      case '8':
      case '9':
        break;
      case '-':
        if (value.search(/^\-{1}/) !== -1) {
          // если найден минус вначале строки
          event.preventDefault();
        } else if (value.search(/^\d*((\.|\,)\d{3})?$/) !== -1) {
          lastChanceForMinus = true; // дает последний шанс поставить минус в нужное место
        }
        break;
      case '.':
      case ',':
        if (hasDot || hasComma || value === '') {
          event.preventDefault();
          return;
        }
        break;
      default:
        event.preventDefault();
        return;
    }

    if (
      event.target.value.search(/^\-?.+?\-.+$/) === -1 &&
      value.search(/^\-?\d*((\.|\,)\d{0,3})?$/) !== -1
    ) {
      // пока не найден минус посередине и значение подходит под маску
      const tmpValue = String(value).replace(',', '.');
      if (tmpValue === '') {
        this.lastValidInput = tmpValue;
        return;
      }
      const decimal = new Decimal(tmpValue).toFixed().toString();
      this.lastValidInput = decimal; // хранит последнее валидное значение
    }
  }

  public onBlur(event, row, name) {
    if (event.target.value.search(/^\-?.+?\-.+$/) !== -1) {
      // если минус посередине найден
      row[name] = this.lastValidInput;
    } else if (event.target.value.search(/^\-?\d*((\.|\,)\d{0,3})?$/) !== -1) {
      const tmpValue = String(event.target.value).replace(',', '.');
      if (tmpValue === '') {
        row[name] = tmpValue;
        return;
      }
      const decimal = new Decimal(tmpValue).toFixed().toString();
      row[name] = decimal;
    } else {
      row[name] = this.lastValidInput;
    }
  }

  public input(event) {
    let str = String(event.target.value);
    const hasDot = str.indexOf('.') !== -1;
    const hasComma = str.indexOf(',') !== -1;
    if (hasDot) {
      event.target.value = str.slice(0, 4 + str.indexOf('.'));
    }
    if (hasComma) {
      event.target.value = str.slice(0, 4 + str.indexOf(','));
    }
    str = String(event.target.value);

    if (str.search(/^\-?\d*((\.|\,)\d{0,3})?$/) === -1) {
      event.target.value = this.lastValidInput;
    }
  }

  public keyUp(row, name) {
    /*const str = String(row[name]);
    const hasDot = str.indexOf('.') !== -1;
    const hasComma = str.indexOf(',') !== -1;

    if (!hasDot && !hasComma) {
      return;
    }
    if (hasDot && hasComma) {
      row[name] = String(row[name]).slice(0, String(row[name]).length - 1);
      return;
    }
    if (str.lastIndexOf('.') !== str.indexOf('.')) {
      row[name] = String(row[name]).slice(0, String(row[name]).length - 1);
      return;
    }
    if (str.lastIndexOf(',') !== str.indexOf(',')) {
      row[name] = String(row[name]).slice(0, String(row[name]).length - 1);
      return;
    }
    if (hasDot) {
      row[name] = str.slice(0, 4 + str.indexOf('.'));
      return;
    }
    if (hasComma) {
      row[name] = str.slice(0, 4 + str.indexOf(','));
      return;
    }*/
  }

  public onPaste(event) {
    event.preventDefault();
  }

  // конец валидации поля "значение"
}
