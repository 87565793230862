import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  AfterViewChecked
} from '@angular/core';

@Directive({
  selector: '[emptyRow]'
})
export class EmptyRowDirective implements OnInit, AfterViewChecked {
  textElement: any;
  actionElement: any;

  constructor(private renderer: Renderer2, private hostElement: ElementRef) {}

  isInited = false;
  isNulled = false;

  ngOnInit() {
    this.hostElement.nativeElement
      .getElementsByClassName('datatable-body')[0]
      .addEventListener('scroll', event => {
        let tmpElHead = this.hostElement.nativeElement
          .getElementsByClassName('datatable-header')[0]
          .getElementsByClassName('datatable-row-center')[0];
        tmpElHead.style.transform = `translate3d(-${event.srcElement.scrollLeft}px, 0, 0)`;
      });
    this.isInited = true;
  }

  ngAfterViewChecked() {
    const tmpEmptyRow = this.hostElement.nativeElement.getElementsByClassName(
      'empty-row'
    )[0];

    if (!tmpEmptyRow) {
      this.isNulled = false;
      return;
    }

    if (!this.isNulled) {
      let tmpElHead = this.hostElement.nativeElement
        .getElementsByClassName('datatable-header')[0]
        .getElementsByClassName('datatable-row-center')[0];
      tmpElHead.style.transform = `translate3d(0, 0, 0)`;
      let tmpElbody = this.hostElement.nativeElement.getElementsByClassName(
        'datatable-body'
      )[0];
      tmpElbody.scrollLeft = 0;
      this.isNulled = true;
    }

    tmpEmptyRow.style.width = this.hostElement.nativeElement.getElementsByClassName(
      'datatable-row-center'
    )[0].style.width;
  }
}
