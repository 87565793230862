import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from '@angular/material/tree';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

import { fromEvent } from 'rxjs';
import 'rxjs/add/operator/debounceTime';

import { AuthenticationService } from '../../services/authentication.service';

interface list {
  name: string;
  children?: list[];
  link: string;
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  link: string;
}
@Component({
  selector: 'personal-page',
  templateUrl: './personalPage.component.html',
  styleUrls: ['./personalPage.component.less']
})
export class PersonalPageComponent implements AfterViewInit {
  private users;
  private bindings;
  private bindingsFiltered;
  public currentUser;
  public isOpened = false;
  public comment = '';
  public modalData: any;
  public isModalOpen = false;
  @ViewChild('tree', { static: false }) tree;
  selected = this.location.path();
  loading;
  private _transformer = (node: list, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      link: node.link
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    public location: Location,
    private authenticationService: AuthenticationService,
    private _spinner: NgxSpinnerService,
  ) {
    let TREE_DATA: list[] = [];
    const currentUser = this.authenticationService.currentUserValue;
    this.modalData = {
      id: '',
      action: 0
    }
    const usersDefault = [
      {
        id: 1,
        name: 'Администратор'
      },
      {
        id: 2,
        name: 'Иванов Николай Игоревич'
      },
      {
        id: 3,
        name: 'Петров Александр Сергеевич'
      },
      {
        id: 4,
        name: 'Шорников Денис Григорьевич'
      },
      {
        id: 5,
        name: 'Желтов Дмитрий Александрович'
      },
      {
        id: 6,
        name: 'Аникин Дмитрий Николаевич'
      },
      {
        id: 7,
        name: 'Щелканова Маргарита Геннадьевна'
      }
    ];
    if (
      currentUser &&
      currentUser.abilities &&
      currentUser.abilities.includes(1)
    ) {
      TREE_DATA.push({
        name: 'Управление привязками',
        link: '/link1',
        children: [
          { name: 'Просмотр и удаление привязок', link: '/link1' },
          { name: 'Привязка к орг. структуре', link: '/link2' },
          { name: 'Привязка к измерениям', link: '/link3' }
        ]
      });
    }
    if (
      currentUser &&
      currentUser.abilities &&
      currentUser.abilities.includes(2)
    ) {
      TREE_DATA.push(
        {
          name: 'Ввод значений показателей',
          link: '/link4'
        },
        {
          name: 'Просмотр истории ввода',
          link: '/link5'
        },
        {
          name: 'Личный кабинет',
          link: '/link6'
        },
        {
          name: 'Личный кабинет пользователя',
          link: '/user'
        }
      );
    }
    if (!localStorage.hasOwnProperty('users')) {
      localStorage.setItem('users', JSON.stringify(usersDefault));
    }
    this._spinner.show();
    this.dataSource.data = TREE_DATA;

    this.users = JSON.parse(localStorage.getItem('users'));
    this.bindings = localStorage.hasOwnProperty('bindings') ? JSON.parse(localStorage.getItem('bindings')) : {};
  }
  public onUserChange = (value) => {
    this.currentUser = value;
    this.isOpened = true;
    if (this.bindings && this.bindings.length) {
      if (value !== 1) {
        this.bindingsFiltered = this.bindings.filter(elem => elem.users.includes(value) && !elem.voted.includes(value));
      } else {
        this.bindingsFiltered = this.bindings;
      }
    }
  }
  public onCommentChange = (value) => {
    this.comment = value;
    console.log(value);
  }
  public openModal = (object) => {
    this.modalData = object;
    this.isModalOpen = true;
  }
  public closeModal = () => {
    this.modalData = null;
    this.isModalOpen = false;
  }
  public onConfirm = (id, action) => {
    let bindings = JSON.parse(localStorage.getItem('bindings'));
    const users = JSON.parse(localStorage.getItem('users'));
    const bindingsIds = bindings.map(elem => elem.id.toString());
    if (bindingsIds && bindingsIds.includes(id.toString()) && this.currentUser) {
      bindings.filter(elem => elem.id.toString() === id.toString()
        && elem.users.includes(this.currentUser)
        && !elem.voted.filter(user => user.id === this.currentUser).length).map((elem, i) => {
        if (bindingsIds.includes(elem.id.toString())) {
          let index = 0;
          bindings.map((b, ind) => {
            if (b.id.toString() === elem.id.toString()) {
              index = ind;
            }
          });
          if (bindings[index].users.includes(this.currentUser)) {
            bindings[index].voted.push({
              id: this.currentUser,
              name: users.filter(userEl => userEl.id === this.currentUser)[0].name,
              decision : action,
              comment: this.comment
            });
            if (bindings[index].voted.length === bindings[index].users.length) {
              if (!bindings[index].voted.filter(userV => userV.decision === 0).length) {
                bindings[index].status = 2;
              } else {
                bindings[index].status = 3;
              }
            } else if (bindings[index].voted.length > 0) {
              bindings[index].status = 1;
            } else {
              bindings[index].status = 0;
            }
          }
        }
      });
    }
    this.bindings = bindings;
    localStorage.setItem('bindings', JSON.stringify(bindings));
    if (this.bindings && this.bindings.length) {
      this.bindingsFiltered = this.bindings.filter(elem => elem.users.includes(this.currentUser)
        && !elem.voted.filter(user => user.id === this.currentUser).length);
    }
    this.modalData = null;
    this.isModalOpen = false;
    this.comment = '';
  }
  ngAfterViewInit() {
    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data.some(item => {
        if (item.children) {
          this.treeControl.expandAll();
        }
      });
    }
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  setSelected(name: string) {
    this.selected = name;
  }

  logout() {
    this.loading = true;
    this.authenticationService.logout();
  }
}
