import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy
} from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats
} from '@angular/material/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import * as _moment from 'moment';

import { AppService } from '../../services/app-service';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'example-header',
  templateUrl: './custom-dpheader.component.html',
  styleUrls: ['./custom-dpheader.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomDPHeaderComponent<D> implements OnDestroy {
  private _destroyed = new Subject<void>();
  private customPeriod = '';

  constructor(
    private _service: AppService,
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  private readonly yearsPerPage = 24;

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  currentPeriodClicked() {
    this.customPeriod = this._service.getCustomPeriod();

    switch (this.customPeriod) {
      case 'Сутки':
      case 'Неделя':
        switch (this._calendar.currentView) {
          case 'month':
            this._calendar.currentView = 'year';
            break;
          case 'year':
            this._calendar.currentView = 'multi-year';
            break;
          case 'multi-year':
          default:
            this._calendar.currentView = 'month';
            break;
        }
        break;
      case 'Месяц':
      case 'Квартал':
        switch (this._calendar.currentView) {
          case 'year':
            this._calendar.currentView = 'multi-year';
            break;
          case 'multi-year':
          default:
            this._calendar.currentView = 'year';
            break;
        }
        break;
      case 'Год':
        break;
      default:
        this._calendar.currentView =
          this._calendar.currentView == 'month' ? 'multi-year' : 'month';
        break;
    }
  }

  get periodLabel() {
    /* public getConvertedDate() {
      let convertedDate;
      if (this.dateTypeId === 2) {
        const month = moment(this.date.value).month();
        const quarterArray = [
          "I квартал",
          "",
          "",
          "II квартал",
          "",
          "",
          "III квартал",
          "",
          "",
          "IV квартал",
          "",
          ""
        ];
        convertedDate = `${quarterArray[month]} ${moment(this.date.value)
          .locale("ru")
          .format("YYYY")}`;
      } else if (this.period === "month") {
        convertedDate = moment(this.date.value)
          .locale("ru")
          .format("LL");
      } else if (this.period === "year") {
        convertedDate = moment(this.date.value)
          .locale("ru")
          .format("MMMM YYYY");
      } else if (this.period === "multi-year") {
        convertedDate = moment(this.date.value)
          .locale("ru")
          .format("YYYY");
      }
      return convertedDate;
    }*/
    this.customPeriod = this._service.getCustomPeriod();
    let convertedDate;

    switch (this.customPeriod) {
      /*case "Неделя":
        const week = moment(this._calendar.activeDate).week();
        const year = moment(this._calendar.activeDate).year();
        convertedDate = `${week} неделя ${year}`;
        break;*/
      case 'Квартал':
        const month = moment(this._calendar.activeDate).month();
        const quarterArray = [
          'I квартал',
          '',
          '',
          'II квартал',
          '',
          '',
          'III квартал',
          '',
          '',
          'IV квартал',
          '',
          ''
        ];
        convertedDate = `${quarterArray[month]} ${moment(
          this._calendar.activeDate
        )
          .locale('ru')
          .format('YYYY')}`;
        break;
      case 'Год':
        convertedDate = moment(this._calendar.activeDate)
          .locale('ru')
          .format('YYYY');
        break;
      case 'Сутки':
      case 'Неделя':
      case 'Месяц':
      default:
        convertedDate = this._dateAdapter
          .format(
            this._calendar.activeDate,
            this._dateFormats.display.monthYearLabel
          )
          .toLocaleUpperCase();
        break;
    }

    return convertedDate;
  }

  previousClicked() {
    /*if ((this._calendar.currentView === 'year') || (this._calendar.currentView === 'multi-year')) {
      this._calendar.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    } else {
      this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1);
    }*/
    this._calendar.activeDate =
      this._calendar.currentView == 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(
            this._calendar.activeDate,
            this._calendar.currentView == 'year' ? -1 : -this.yearsPerPage
          );
  }

  nextClicked() {
    /*if ((this._calendar.currentView === 'year') || (this._calendar.currentView === 'multi-year')) {
      this._calendar.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    } else {
      this._calendar.activeDate = this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1);
    }*/
    this._calendar.activeDate =
      this._calendar.currentView == 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(
            this._calendar.activeDate,
            this._calendar.currentView == 'year' ? 1 : this.yearsPerPage
          );
  }
}
