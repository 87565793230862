import { Component, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import _ from 'lodash';
import { AppService } from '../../services/app-service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { column, pageLimitOptions } from '../../../model/table';
import * as mock from './mock.json';
import {ToastrService} from 'ngx-toastr';


@Component({
    selector: 'binding-indicators',
    templateUrl: './binding-indicators.component.html',
    styleUrls: ['./binding-indicators.component.less']
})
export class BindingIndicatorComponent {
    public dictionaries = {
      'departmentId': [],
      'unitTypeId': [],
      'valueTypeId': [],
      'metricTypeId': [],
      'dataTypeId': [],
      'dateTypeId': []
    };

    public model = {
        department: null,
        unit: null,
        valueType: null,
        metricType: null,
        dataType: null,
        dateType: null,
        indicatorsIds: []
    };

    public rows = [];
    public columns: column[] = [{ name: 'name', title: 'Наименование показателя' }];
    public old_row = [];

    public loading: boolean = true;
    public pageLimitOptions: pageLimitOptions[] = [
        { value: 5, viewValue: '5' },
        { value: 10, viewValue: '10' },
        { value: 20, viewValue: '20' },
        { value: 100, viewValue: '100' }
    ];
    public pageCondition = false;
    public currentPageLimit = 5;
    public subscriptions: Subscription = new Subscription();
    public savingData = false;
    @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

    constructor(private _service: AppService,
                private _spinner: NgxSpinnerService,
                private toastr: ToastrService
    ) {
        this._spinner.show();
        this._service.getDictionary('indicatorId').subscribe(result => {
            this.loading = false;
            const new_result = result.map((item) => {
              return {
                id: item.id,
                name: item.name,
                chosen: false}
            });
            this.rows = [...new_result];
            this.old_row = [...new_result];
        });

      // подписываемся на все словари, которые нам нужны в селектах
      Object.keys(this.dictionaries).map(item => {
        this.subscriptions.add(
          this._service.getDictionary(item).subscribe(data => {
            this.dictionaries[item] = data;
          })
        );
      });
    }
    public saveData(): void {
      this.savingData = true;
      if (!_.isEmpty(this.model.indicatorsIds)) {
        if (_.isEmpty(this.model.department)
            || _.isEmpty(this.model.unit)
            || _.isEmpty(this.model.valueType)
            || _.isEmpty(this.model.metricType)
            || _.isEmpty(this.model.dataType)
            || _.isEmpty(this.model.dateType)) {
          this.toastr.error('Вы не выбрали измерение', '', {enableHtml: true, timeOut: 5000});
          this.savingData = false;
        } else {
            let response = {
                "departmentId": this.model.department.id,
                "unitId": this.model.unit.id,
                "valueTypeId": this.model.valueType.id,
                "metricTypeId": this.model.metricType.id,
                "dataTypeId": this.model.dataType.id,
                "dateTypeId": this.model.dateType.id,
                "indicatorIds": this.model.indicatorsIds
            };
            this._service.postData('/indicatorMeasurement', response).subscribe(result => {
              this.savingData = false;
              if (result !== 'error') {
                if (result === false) {
                  this.toastr.warning('Некоторые из выбранных показателей уже имеют данную привязку.<br>Для них данная привязка останется без изменений.', '', {enableHtml: true, timeOut: 5000});
                } else {
                  this.toastr.success('Привязка показателей к измерениям успешно сохранена', '', {enableHtml: true, timeOut: 5000});
                }
              }
            })
        }
      } else {
        this.savingData = false;
        this.toastr.error('Вы не выбрали показатель', '', {enableHtml: true, timeOut: 5000});
      }
    }

    public updateFilter(event) {
      let val = event.target.value.toLowerCase();
      this.rows = this.old_row.filter(function (item) {
        if (item.name.toString().toLowerCase().indexOf(val) !== -1 || !val) {
          return true;
        }
      });
      // при фильтрации переходим на 1 страницу
      this.table.offset = 0;
    }

    public PageSelectionChange(value, rowCount) {
        this.currentPageLimit = value;
        if (value >= rowCount) {
            this.pageCondition = true;
        } else {
            this.pageCondition = false;
        }
    }

    public changeChecked(indicator): void {
        if (this.model.indicatorsIds.includes(indicator.id)) {
            this.model.indicatorsIds.splice(this.model.indicatorsIds.indexOf(indicator.id), 1);
            this.rows.filter(item => item.id === indicator.id)[0].chosen = false;
        } else {
            this.model.indicatorsIds.push(indicator.id);
            this.rows.filter(item => item.id === indicator.id)[0].chosen = true;
        }
    }
}
