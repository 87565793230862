import { Component, ViewChild, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {formatDate} from '@angular/common';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import * as mock from './mock.json';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { AppService } from '../../services/app-service';
import { MY_FORMATS } from './../../../model';
import { ApprovementService } from './approvement.service';

import { CustomDPHeaderComponent } from './../custom-dpheader/custom-dpheader.component';
import { AuthenticationService } from '../../services/authentication.service';
import { MatRadioGroup } from '@angular/material';
import { HttpHeaders } from '@angular/common/http';
import { RequestOptions } from '@angular/http';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'approvement',
  templateUrl: './approvement.component.html',
  styleUrls: ['./approvement.component.less'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class ApprovementComponent {
  exampleHeader = CustomDPHeaderComponent; // кастомная верхушка календаря
  private currentUser = this.authenticationService.currentUserValue;
  public radioOptions = [
    {id: 1, name: 'Утвердить'},
    {id: 0, name: 'Отклонить'}
  ];

  public rows = []; // содержит изменения в визуальных строках, полный комплект как в source_rows.
  public source_rows = []; // неизменяемые строки, обновляются только после сохранения.
  public filtered_rows = []; // строки в таблице, часть от rows. Передача по ссылке.
  public loading: boolean = true;
  public savingData = false;
  public userInfo: any;

  public columnsDB = [
    { name: 'indicator', title: 'Наименование показателя' },
    { name: 'unitType', title: 'Ед. изм.' },
    { name: 'orgStruct', title: 'Орг. структура' },
    { name: 'valueTypeId', title: 'Тип значений', dictionary: true },
    { name: 'dataType', title: 'Тип данных' },
    { name: 'metricType', title: 'Метрика данных' },
    { name: 'dateType', title: 'Тип периода' },
    { name: 'adjustmentReason', title: 'Причина корректировки', input: true },
    { name: 'value', title: 'Значение', inputNum: true }
  ];
  public columns = [
    { name: 'indicator_id', title: 'Наименование показателя', dictionary: true  },
    { name: 'unit_id', title: 'Ед. изм.' , dictionary: true },
    { name: 'road_id', title: 'Орг. структура' , dictionary: true },
    { name: 'value_type_id', title: 'Тип значений', dictionary: true },
    { name: 'data_type_id', title: 'Тип данных', dictionary: true  },
    { name: 'metric_type_id', title: 'Метрика данных', dictionary: true  },
    { name: 'date_type_id', title: 'Тип периода', dictionary: true  },
    { name: 'adjustmentReason', title: 'Комментарий', input: true },
    { name: 'value', title: 'Значение', inputNum: true },
    { name: 'approvement', title: 'Решение', radio: true }
  ];

  public currentPageLimit = 5;
  public pageCondition = false;
  public pageLimitOptions = [
    { value: 5, viewValue: '5' },
    { value: 10, viewValue: '10' },
    { value: 20, viewValue: '20' },
    { value: 100, viewValue: '100' }
  ];

  public dictionaries = {};
  public periodFilter = undefined;
  public selectPeriods = [];
  public approvedRows = [];
  public period = 'year';
  public departments = [];
  public departmentId = 0;
  public orgStructFilter;
  public selectStructures = [
    { id: 'all', name: 'Все' },
    { id: 'roads', name: 'Дороги' },
    { id: 'territorialAdministration', name: 'Ту' },
    { id: 'dispatchArea', name: 'Ду' }
  ];
  private tableFilterInput = '';
  public convertedDate = '';
  public date: FormControl = new FormControl(moment());
  private subscriptions: Subscription = new Subscription();
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  constructor(
    private _service: AppService,
    private _spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public inputService: ApprovementService,
    private authenticationService: AuthenticationService
  ) {
    this._spinner.show();
    this._service.getDictionary('departmentId').subscribe(result => {
      if (this.currentUser && this.currentUser.departments.length) {
        this.departments = [
          ...result.filter(deprt => {
            if (deprt.id && this.currentUser.departments.includes(deprt.id)) {
              return deprt;
            } // отфильтрует полученные данные по массиву разрешенных департаментов
          })
        ];
      } else {
        this.departments = [{id: 1, name: 'Интер РАО'}];
      }
      if (
        !(
          this.currentUser &&
          this.currentUser.abilities &&
          this.currentUser.abilities.includes(1)
        )
      ) {
        this.departmentId = this.departments.length ? this.departments[0].id : 1;
      }
    });
    this._service.getDictionary('dateTypeId').subscribe(result => {
      this.selectPeriods = result.filter(p => p.name !== 'Не задано');
      /*this.periodFilter =
        this.currentUser &&
        this.currentUser.abilities &&
        this.currentUser.abilities.includes(1)
          ? undefined
          : result[1];*/
      this.loadData();
    });
    this._service.getDataLuxms('auth/check').subscribe(info => {
      this.userInfo = info;
    });
    this.loading = false;
    // подписываемся на все словари, которые нам нужны в табличке
    this.columns.forEach(item => {
      if (item.dictionary) {
        this.dictionaries[item.name] = {};
        if (item.name != 'approvement') {
          if (item.name == 'road_id') {
            this.subscriptions.add(
              this._service.getDictionaryDBAPI('road_id').subscribe(data => {
                this.dictionaries['road_id'] = data;
              })
            );
            this.subscriptions.add(
              this._service.getDictionaryDBAPI('territorial_administration_id').subscribe(data => {
                this.dictionaries['territorial_administration_id'] = data;
              })
            );
            this.subscriptions.add(
              this._service.getDictionaryDBAPI('dispatch_area_id').subscribe(data => {
                this.dictionaries['dispatch_area_id'] = data;
              })
            );
          } else {
            this.subscriptions.add(
              this._service.getDictionaryDBAPI(item.name).subscribe(data => {
                this.dictionaries[item.name] = data;
              })
            );
          }
        }
      }
    });
    console.log(this.dictionaries);
  }

  public RadioSelectChange(value, row) {
    console.log(row);
    const userInfo = this.userInfo && Object.keys(this.userInfo).length ? this.userInfo : {};
    if (Object.keys(userInfo).length) {
      row.approved.by[userInfo.username] = {
        id: userInfo.id,
        decision: value,
        comment: row.adjustmentReason,
        reportDate: formatDate(new Date(), 'yyyy-MM-dd', 'en')
      };
      const filteredApprovements = this.approvedRows.filter(approvementRow => approvementRow.id == row.id);
      if (!filteredApprovements.length) {
        this.approvedRows = [
          ...this.approvedRows,
          row
        ];
      }
      console.log(this.approvedRows);
    }
  }

  public getDictionaryData(name, id, row): string {
    let value;
    if (name == 'road_id') {
      const road = row.road_id;
      const tu = row.territorial_administration_id;
      const du = row.dispatch_area_id;
      this.dictionaries['road_id'].forEach(item => {
        if (item.id == id) {
          value = item.name;
        }
      });
      if (tu !== null && du === null) {
        this.dictionaries['territorial_administration_id'].forEach(item => {
          if (item.id == tu) {
            value += '; ' + item.name;
          }
        });
      } else if (tu === null && du !== null) {
        this.dictionaries['dispatch_area_id'].forEach(item => {
          if (item.id == du) {
            value += '; ' + item.name;
          }
        });
      }
    } else {
      this.dictionaries[name].forEach(item => {
        if (item.id == id) {
          value = item.name;
        }
      });
    }
    return value;
  }

  public chosenYearHandler(
    normalizedDate: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedDate.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.orgStructFilter = undefined;
  }

  public chosenMonthHandler(
    normalizedDate: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedDate.month());
    ctrlValue.year(normalizedDate.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.orgStructFilter = undefined;
  }
  public chosenDayHandler() {
    if (this.periodFilter.name === 'Квартал') {
      this.convertToQuarter();
    } else if (this.periodFilter.name === 'Неделя') {
      this.convertToWeek();
    }
    this.loadData();
    this.orgStructFilter = undefined;
  }

  public convertToQuarter() {
    const month = moment(this.date.value).month();
    switch (month) {
      case 0:
      case 1:
      case 2:
        this.date.setValue(
          moment(this.date.value)
            .month(0)
            .date(1)
        );
        break;

      case 3:
      case 4:
      case 5:
        this.date.setValue(
          moment(this.date.value)
            .month(3)
            .date(1)
        );
        break;

      case 6:
      case 7:
      case 8:
        this.date.setValue(
          moment(this.date.value)
            .month(6)
            .date(1)
        );
        break;

      default:
        this.date.setValue(
          moment(this.date.value)
            .month(9)
            .date(1)
        );
        break;
    }
  }

  public convertToWeek() {
    const week = moment(this.date.value).week();
    this.date.setValue(
      moment()
        .day('Monday')
        .week(week)
    );
  }

  public getConvertedDate() {
    let convertedDate;
    if (this.periodFilter.name === 'Квартал') {
      const month = moment(this.date.value).month();
      const quarterArray = [
        'I квартал',
        '',
        '',
        'II квартал',
        '',
        '',
        'III квартал',
        '',
        '',
        'IV квартал',
        '',
        ''
      ];
      convertedDate = `${quarterArray[month]} ${moment(this.date.value)
        .locale('ru')
        .format('YYYY')}`;
    } else if (this.period === 'month') {
      convertedDate = moment(this.date.value)
        .locale('ru')
        .format('LL');
    } else if (this.period === 'year') {
      convertedDate = moment(this.date.value)
        .locale('ru')
        .format('MMMM YYYY');
    } else if (this.period === 'multi-year') {
      convertedDate = moment(this.date.value)
        .locale('ru')
        .format('YYYY');
    }
    return convertedDate;
  }

  public PageSelectionChange(value, rowCount) {
    this.currentPageLimit = value;
    if (value >= rowCount) {
      this.pageCondition = true;
    } else {
      this.pageCondition = false;
    }
  }

  public loadData() {
    if (this.departmentId && this.periodFilter) {
      const params = {
        report_date: undefined,
        date_type_id: undefined,
        department_id: this.departmentId
      };
      const period = this.periodFilter.name;
      if (period !== 'Не задано') {
        params.date_type_id = this.selectPeriods.filter(
          item => item.name === period
        )[0].id;

        let newDate = moment(this.date.value);
        if (period === 'Месяц') {
          newDate = moment(this.date.value).date(1);
        } else if (period === 'Год') {
          newDate = moment(this.date.value)
            .month(0)
            .date(1);
        }

        params.report_date = newDate.format('YYYY-MM-DD');

        this.loading = true;
        this._service
          .getDataBodyDBAPI('kpicol.indicator_value', params)
          .subscribe(result => {
            this.loading = false;

            let buffer;
            if (this.currentUser && this.currentUser.roads) {
              buffer = result.filter(item => {
                if (
                  item.road_id &&
                  this.currentUser.roads &&
                  this.currentUser.roads.includes(item.road_id)
                ) {
                  return item;
                } // отфильтрует полученные данные по массиву разрешенных дорог
              });
              buffer = buffer.map(row => {
                row.approvement = -1;
                return row;
              });
              if (this.userInfo && Object.keys(this.userInfo).length) {
                buffer = buffer.filter(row => {
                  if (Object.keys(row.approved.by).length) {
                    return !row.approved.by.hasOwnProperty(this.userInfo.username);
                  } else {
                    return true;
                  }
                });
              }
              this.rows = JSON.parse(JSON.stringify(buffer));
              this.source_rows = JSON.parse(JSON.stringify(buffer));
            }
            this.runFilter();
          });
      }
    }
  }

  public departmentChange() {
    this.loadData();
    this.orgStructFilter = undefined;
    this.table.offset = 0;
  }

  // одинаков
  public updateFilter(event) {
    this.tableFilterInput = event.target.value.toLowerCase();
    this.runFilter();
  }

  public selectionChange(event, key) {
    this.date.setValue(moment());
    if (!_.isEmpty(this.periodFilter)) {
      this._service.setCustomPeriod(this.periodFilter.name);
      switch (this.periodFilter.name) {
        case 'Сутки':
          this.period = 'month';
          break;

        case 'Неделя':
          this.period = 'month';
          this.convertToWeek();
          break;

        case 'Месяц':
          this.period = 'year';
          break;

        case 'Квартал':
          this.period = 'year';
          this.convertToQuarter();
          break;

        case 'Год':
          this.period = 'multi-year';
          break;

        default:
          this.period = 'multi-year';
          break;
      }
    } else {
      this.period = 'multi-year';
    }
    this.table.offset = 0;
    this.loadData();
    this.orgStructFilter = undefined;
  }

  public saveData() {
    this.savingData = true;
    const periodFilter = this.periodFilter ? this.periodFilter.name : null;

    if (!this.departmentId || !periodFilter) {
      this.savingData = false;
      if (!this.departmentId) {
        this.showMessage('Выберите департамент', 'error');
      } else {
        this.showMessage('Выберите тип периода', 'error');
      }
      return;
    }

    if (!this.approvedRows.length) {
      this.showMessage('Нет данных для сохранения');
      this.savingData = false;
      return;
    }
    this.approvedRows.map((approvedRow, index) => {
      this.savingData = false;
      this._service
        .patchDataDBAPI('kpicol.indicator_value.approved/' + approvedRow.id, approvedRow.approved)
        .subscribe(result => {
          if (index === this.approvedRows.length - 1) {
            this.savingData = false;
            if (result !== 'error') {
              this.showMessage(
                'Согласование выбранных показателей успешно сохранено',
                'success'
              );
            }
          }
        });
    });
  }

  private showMessage(text = 'Стандартный текст сообщения', color = 'warning') {
    switch (color) {
      case 'success':
        this.toastr.success(text, '', { enableHtml: true, timeOut: 5000 });
        break;

      case 'error':
        this.toastr.error(text, '', {
          enableHtml: true,
          timeOut: 5000
        });
        break;
      case 'warning':
      default:
        this.toastr.warning(text, '', {
          enableHtml: true,
          timeOut: 5000
        });
        break;
    }
  }

  private formatSendingData(rows) {
    const periodFilter = this.periodFilter ? this.periodFilter.name : null;
    const date = this.date;

    let newDate = moment(date.value);
    if (periodFilter === 'Месяц') {
      newDate = moment(date.value).date(1);
    } else if (periodFilter === 'Год') {
      newDate = moment(date.value)
        .month(0)
        .date(1);
    }

    return rows.map(row => {
      return {
        adjustmentReason: row.adjustmentReason,
        dataTypeId: row.dataTypeId,
        dateTypeId: row.dateTypeId,
        departmentId: this.departmentId,
        dispatchAreaId: row.dispatchAreaId,
        indicatorId: row.indicatorId,
        metricTypeId: row.metricTypeId,
        reportDate: newDate.format('YYYY-MM-DD'),
        roadId: row.roadId,
        territorialAdministrationId: row.territorialAdministrationId,
        unitId: row.unitTypeId,
        value: row.value === '' ? null : row.value,
        valueTypeId: row.valueTypeId
      };
    });
  }

  private checkRowsBeforeSending() {
    return this.rows.filter((row, index) => {
      if (!_.isEqual(row, this.source_rows[index])) {
        const imutableRow = this.source_rows[index];

        const isReasonChanged =
          String(row.adjustmentReason) !== String(imutableRow.adjustmentReason);
        const isValueChanged = String(row.value) !== String(imutableRow.value);
        const isReasonEmptyString =
          row.adjustmentReason === null ||
          String(row.adjustmentReason).trim() === '';
        const isValueEmptyString =
          row.value === null || String(row.value).trim() === '';

        const isNew = !imutableRow.value;
        const newText = isNew ? 'new' : 'old';

        const isJustSeparatorChanged =
          row.value.replace(',', '.') === String(imutableRow.value) ||
          row.value.replace('.', ',') === String(imutableRow.value);

        const switchCondition =
          '' + isReasonChanged + '-' + isValueChanged + '|' + newText;

        switch (switchCondition) {
          case 'true-false|new':
            if (!isReasonEmptyString) {
              this.showMessage(
                'Нет данных для сохранения.<br>Пустое значение.'
              );
              throw new Error('true-false|new');
            }
            break;

          case 'false-true|new':
            if (!isValueEmptyString && !isJustSeparatorChanged) {
              return true;
            }
            break;

          case 'true-true|new':
            if (
              !isReasonEmptyString &&
              !isValueEmptyString &&
              !isJustSeparatorChanged
            ) {
              return true;
            } else if (
              isReasonEmptyString &&
              !isValueEmptyString &&
              !isJustSeparatorChanged
            ) {
              return true;
            } else if (
              !isReasonEmptyString &&
              isValueEmptyString &&
              !isJustSeparatorChanged
            ) {
              this.showMessage(
                'Нет данных для сохранения.<br>Пустое значение.'
              );
              throw new Error('true-true|new');
            }
            break;

          case 'true-false|old':
            this.showMessage(
              'Нет данных для сохранения.<br>Значение не изменено.'
            );
            throw new Error('true-false|old');
            break;

          case 'false-true|old':
            if (!isJustSeparatorChanged) {
              this.showMessage('Не изменено поле "причина корректировки"');
              throw new Error('false-true|old');
            }
            break;

          case 'true-true|old':
            if (
              !isReasonEmptyString &&
              !isValueEmptyString &&
              !isJustSeparatorChanged
            ) {
              return true;
            } else if (isReasonEmptyString && !isJustSeparatorChanged) {
              this.showMessage('Не указано поле "причина корректировки"');
            } else if (!isJustSeparatorChanged) {
              return true;
            }
            break;

          case 'false-false|old':
          case 'false-false|new':
            break;

          default:
            this.showMessage('Непредвиденная ситуация.', 'error');
            throw new Error('true-true|old');
            break;
        }
      }
    });
  }

  public runFilter() {
    if (!this.source_rows.length) {
      this.filtered_rows = [];
      return [];
    }
    this.table.offset = 0;

    let buffer = this.rows;

    if (!_.isEmpty(this.orgStructFilter)) {
      const filter = this.orgStructFilter.id;
      buffer = buffer.filter(item => {
        if (
          filter === 'all' ||
          (filter === 'roads' &&
            item.dispatch_area_id === null &&
            item.territorial_administration_id === null) ||
          (filter === 'territorialAdministration' &&
            item.territorial_administration_id !== null) ||
          (filter === 'dispatchArea' && item.dispatch_area_id !== null)
        ) {
          return true;
        }
      });
    }

    const tableFilterInput = this.tableFilterInput;
    const colsAmt = this.columns.length;
    const keys = this.columns.map(item => item.name);
    if (this.userInfo && Object.keys(this.userInfo).length) {
      buffer = buffer.filter(row => {
        if (Object.keys(row.approved.by).length) {
          return !row.approved.by.hasOwnProperty(this.userInfo.username);
        } else {
          return true;
        }
      });
    }
    this.filtered_rows = buffer.filter((item) => {
      for (let i = 0; i < colsAmt; i++) {
        // valueTypeId
        if (item[keys[i]]) {
          if (
            (keys[i] === 'value_type_id' &&
              item.value_type_id
                .toString()
                .toLowerCase()
                .indexOf(tableFilterInput) !== -1) ||
            !tableFilterInput
          ) {
            return true;
          } else if (
            item[keys[i]]
              .toString()
              .toLowerCase()
              .indexOf(tableFilterInput) !== -1 ||
            !tableFilterInput
          ) {
            return true;
          }
        }
      }
    });
    this.filtered_rows = this.filtered_rows.map(row => {
      row.approvement = -1;
      return row;
    });
    if (this.userInfo && Object.keys(this.userInfo).length) {
      this.filtered_rows = this.filtered_rows.filter(row => {
        if (Object.keys(row.approved.by).length) {
          return !row.approved.by.hasOwnProperty(this.userInfo.username);
        } else {
          return true;
        }
      });
    }
    console.log(this.filtered_rows);
  }
}
