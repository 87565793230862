import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from '@angular/material/tree';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { fromEvent, Observable } from 'rxjs';
import 'rxjs/add/operator/debounceTime';

import { AuthenticationService } from '../../services/authentication.service';
import { AppService } from '../../services/app-service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '../../../model';
import { MY_FORMATS_CUSTOM } from '../../../model';
import { default as _moment, default as _rollupMoment } from 'moment';
import { FormControl, FormGroup } from '@angular/forms';

interface list {
  name: string;
  children?: list[];
  link: string;
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  link: string;
}

const moment = _rollupMoment || _moment;
@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.less'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_CUSTOM }
  ]
})

export class AccountComponent implements AfterViewInit {
  private users;
  private bindings: any;
  private indicators;
  public currentIndicator: any;
  public date: any;
  public currentDate = '';
  public currentTitle = '';
  public currentUsers: any;
  public modalData: any;
  public isModalOpen = false;
  @ViewChild('tree', { static: false }) tree;
  selected = this.location.path();
  loading;
  private _transformer = (node: list, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      link: node.link
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    private _service: AppService,
    public location: Location,
    private authenticationService: AuthenticationService,
    private _spinner: NgxSpinnerService
  ) {
    let TREE_DATA: list[] = [];
    const currentUser = this.authenticationService.currentUserValue;
    const usersDefault = [
      {
        id: 1,
        name: 'Администратор'
      },
      {
        id: 2,
        name: 'Иванов Николай Игоревич'
      },
      {
        id: 3,
        name: 'Петров Александр Сергеевич'
      },
      {
        id: 4,
        name: 'Шорников Денис Григорьевич'
      },
      {
        id: 5,
        name: 'Желтов Дмитрий Александрович'
      },
      {
        id: 6,
        name: 'Аникин Дмитрий Николаевич'
      },
      {
        id: 7,
        name: 'Щелканова Маргарита Геннадьевна'
      }
    ];
    if (
      currentUser &&
      currentUser.abilities &&
      currentUser.abilities.includes(1)
    ) {
      TREE_DATA.push({
        name: 'Управление привязками',
        link: '/link1',
        children: [
          { name: 'Просмотр и удаление привязок', link: '/link1' },
          { name: 'Привязка к орг. структуре', link: '/link2' },
          { name: 'Привязка к измерениям', link: '/link3' }
        ]
      });
    }
    if (
      currentUser &&
      currentUser.abilities &&
      currentUser.abilities.includes(2)
    ) {
      TREE_DATA.push(
        {
          name: 'Ввод значений показателей',
          link: '/link4'
        },
        {
          name: 'Просмотр истории ввода',
          link: '/link5'
        },
        {
          name: 'Личный кабинет',
          link: '/link6'
        },
        {
          name: 'Личный кабинет пользователя',
          link: '/user'
        }
      );
    }
    if (!localStorage.hasOwnProperty('users')) {
      localStorage.setItem('users', JSON.stringify(usersDefault));
    }
    if (!localStorage.hasOwnProperty('bindings')) {
      localStorage.setItem('bindings', JSON.stringify([]));
    }
    this._spinner.show();
    this.dataSource.data = TREE_DATA;

    this.users = JSON.parse(localStorage.getItem('users'));
    this.modalData = {
      title: '',
      voted: [],
      users: []
    };
    this.bindings = localStorage.hasOwnProperty('bindings') ? JSON.parse(localStorage.getItem('bindings')) : {};
    const params = {
      reportDate: '2019-01-01',
      dateTypeId: 5,
      departmentId: 1
    };
    this._service
      .getDataBody('/indicator-value', params)
      .subscribe(result => {
        console.log(result);
        // const usedIds = [];
        // let filtered = result.slice().filter(item => {
        //   if (!usedIds.includes(item.indicatorId)) {
        //     usedIds.push(item.indicatorId);
        //     return item;
        //   } else {
        //     return false;
        //   }
        // });
        // filtered = filtered.map((elem, index) => {
        //   if (index === 0) {
        //     return elem;
        //   } else {
        //     elem.indicatorValueId = elem.indicatorValueId + index;
        //     elem.value = elem.value * (index + 1);
        //     return elem;
        //   }
        // });
        // console.log(filtered);
        this.indicators = result;
      });
  }

  public getUnvotedArray = (users, voted) => {
    const usersArr = JSON.parse(localStorage.getItem('users'));
    const unvoted = users.filter(elem => !voted.filter(el => el.id === elem).length);
    const result = unvoted.map(id => {
      return { id, name: usersArr.filter(elem => elem.id === id)[0].name};
    });
    return result;
  }
  private saveBindings = (indicators: any, users: any, date: string, title: string) => {
    const bindings = JSON.parse(localStorage.getItem('bindings'));
    const bindingsIds = bindings.map(elem => elem.id.toString());
    const currentIndicators = indicators.map(indicator => {
      return {
        id: indicator.indicatorId,
        title: indicator.indicator,
        unitType: indicator.unitType,
        value: indicator.value ? indicator.value : 0,
      };
    });
    const element = {
      id: title.toString(),
      indicators: currentIndicators,
      title,
      users,
      voted: [],
      status: 0,
      reportDate: date
    }
    if (!bindingsIds.includes(element.id.toString())) {
      bindings.push(element);
    }
    this.bindings = bindings;
    localStorage.setItem('bindings', JSON.stringify(bindings));
    this.currentDate = '';
    this.currentUsers = [];
    this.currentIndicator = [];
    this.date = null;
    this.currentTitle = '';
  }
  public onAddBindingClick = () => {
    document.getElementById('createBindingForm').classList.add('active');
    document.getElementById('bindingsListButtonContainer').classList.add('hide');
  }
  public closeForm = () => {
    document.getElementById('createBindingForm').classList.remove('active');
    document.getElementById('bindingsListButtonContainer').classList.remove('hide');
  }
  public onIndicatorChange = (value) => {
    this.currentIndicator = value;
  }
  public onTitleChange = (value) => {
    this.currentTitle = value;
  }
  public onBindingItemDelete = (id) => {
    let bindings = JSON.parse(localStorage.getItem('bindings'));
    const bindingsIds = bindings.map(elem => elem.id.toString());
    let indexToDelete = -1;
    if (bindingsIds.includes(id.toString())) {
      bindings.map((elem, i) => {
        if (bindingsIds.includes(elem.id.toString())) {
          indexToDelete = i;
        }
      });
      if (indexToDelete !== -1) {
        bindings.splice(indexToDelete, 1);
      }
    }
    this.bindings = bindings;
    localStorage.setItem('bindings', JSON.stringify(bindings));
  }
  public onDateChange = (value) => {
    const months = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
    this.date = value;
    this.currentDate = value ? value._i.date + ' ' + months[value._i.month] + ' ' + value._i.year : '';
    const params = {
      reportDate: value._i.year + '-01-01',
      dateTypeId: 5,
      departmentId: 1
    };
    this._service
      .getDataBody('/indicator-value', params)
      .subscribe(result => {
        console.log(result);
        // const usedIds = [];
        // let filtered = result.slice().filter(item => {
        //   if (!usedIds.includes(item.indicatorId)) {
        //     usedIds.push(item.indicatorId);
        //     return item;
        //   } else {
        //     return false;
        //   }
        // });
        // filtered = filtered.map((elem, index) => {
        //   if (index === 0) {
        //     return elem;
        //   } else {
        //     elem.indicatorValueId = elem.indicatorValueId + index;
        //     elem.value = elem.value * (index + 1);
        //     return elem;
        //   }
        // });
        this.indicators = result;
      });
  }
  public onUserChange = (value) => {
    this.currentUsers = value;
  }
  public openModal = (object) => {
    this.modalData = object;
    this.isModalOpen = true;
  }
  public closeModal = () => {
    this.modalData = null;
    this.isModalOpen = false;
  }
  public onSubmitForm = (e) => {
    e.preventDefault();
    if (this.currentIndicator && this.currentUsers && this.currentDate && this.currentTitle) {
      this.saveBindings(this.currentIndicator, this.currentUsers, this.currentDate, this.currentTitle);
      document.getElementById('createBindingForm').classList.remove('active');
      document.getElementById('bindingsListButtonContainer').classList.remove('hide');
    }
  }
  public onReportClick = (item: any) => {
    if (item) {
      const dateSplitted: any = item.reportDate.split(' ');
      const year: string = dateSplitted[2];
      const title = encodeURI(item.title);
      const url = '/yarg/generate?report=yr&params[title]=' + title + '&params[year]=' + year;
      window.open(url);
    }
  }
  ngAfterViewInit() {
    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data.some(item => {
        if (item.children) {
          this.treeControl.expandAll();
        }
      });
    }
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  setSelected(name: string) {
    this.selected = name;
  }

  logout() {
    this.loading = true;
    this.authenticationService.logout();
  }
}
