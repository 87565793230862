import { Component, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import _ from 'lodash';
import { AppService } from '../../services/app-service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { column, pageLimitOptions } from '../../../model/table';
import * as mock from './mock.json';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'binding-adjustment',
  templateUrl: './binding-adjustment.component.html',
  styleUrls: ['./binding-adjustment.component.less']
})
export class BindingAdjustmentComponent {
  rows = [];
  columns: column[] = [
    { name: "indicatorId", title: "Показатель", dictionary: true, comparator: this.dictionariesComparatorFunc.bind(this, 'indicatorId')},
    { name: "departmentId", title: "Департамент", dictionary: true, comparator: this.dictionariesComparatorFunc.bind(this, 'departmentId')},
    { name: "unitTypeId", title: "Единица измерения", dictionary: true, comparator: this.dictionariesComparatorFunc.bind(this, 'unitTypeId')},
    { name: "valueTypeId", title: "Тип значений", dictionary: true, comparator: this.dictionariesComparatorFunc.bind(this, 'valueTypeId')},
    { name: "dataTypeId", title: "Тип данных", dictionary: true, comparator: this.dictionariesComparatorFunc.bind(this, 'dataTypeId')},
    { name: "metricTypeId", title: "Метрика данных", dictionary: true, comparator: this.dictionariesComparatorFunc.bind(this, 'metricTypeId')},
    { name: "dateTypeId", title: "Тип периода", dictionary: true, comparator: this.dictionariesComparatorFunc.bind(this, 'dateTypeId')}
  ];
  old_row = [];
  loading: boolean = true;
  pageLimitOptions: pageLimitOptions[] = [
    { value: 5, viewValue: '5' },
    { value: 10, viewValue: '10' },
    { value: 20, viewValue: '20' },
    { value: 100, viewValue: '100' }
  ];
  pageCondition = false;
  currentPageLimit = 5;
  dictionaries = {};
  subscriptions: Subscription = new Subscription();
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(private _service: AppService,
              private _spinner: NgxSpinnerService,
              private toastr: ToastrService) {
    this._spinner.show();

    this._service.getData('/indicatorMeasurement').subscribe(result => {
      this.loading = false;
      this.rows = result;
      this.rows = [...this.rows];
      this.old_row = result;
    });

    // подписываемся на все словари, которые нам нужны в табличке
    this.columns.forEach(item => {
      if (item.dictionary) {
        this.dictionaries[item.name] = {};
        this.subscriptions.add(
          this._service.getDictionary(item.name).subscribe(data => {
            this.dictionaries[item.name] = data;
          })
        );
      }
    });
  }

  getDictionaryData(name, id): string {
    let value;
    this.dictionaries[name].forEach(item => {
      if (item.id == id) value = item.name;
    });
    return value;
  }

  deleteRow(id) {
    this._service.removeData('/indicatorMeasurement', id).subscribe(result => {
      this.rows = [..._.reject(this.rows, function (item) { return item.id == id; })];
      this.old_row = [..._.reject(this.old_row, function (item) { return item.id == id; })];
      this.toastr.success('Привязка успешно удалена', '', {enableHtml: true, timeOut: 5000});
    });
  }

  public PageSelectionChange(value, rowCount) {
    this.currentPageLimit = value;
    if (value >= rowCount) {
      this.pageCondition = true;
    } else {
      this.pageCondition = false;
    }
  }

  updateFilter(event) {
    if (this.old_row && this.old_row.length) {
      let val = event.target.value.toLowerCase();
      let keys = Object.keys(this.old_row[0]);
      let colsAmt = keys.length;
      let _this = this;
      this.rows = this.old_row.filter(function (item) {
        for (let i = 0; i < colsAmt; i++) {
          if (_this.dictionaries[keys[i]]) {
            let value = _this.getDictionaryData(keys[i], item[keys[i]]);
            if (value && value.toString().toLowerCase().indexOf(val) !== -1 || !val) {
              return true;
            }
          } else if (item[keys[i]] && item[keys[i]].toString().toLowerCase().indexOf(val) !== -1 || !val) {
            return true;
          }
        }
      });
    } else {
      this.rows = this.old_row;
    }
    // при фильтрации переходим на 1 страницу
    this.table.offset = 0;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    this._spinner.hide();
  }

  dictionariesComparatorFunc (prop, propA, propB) {
    let nameA = this.dictionaries[prop].filter(item => item.id === propA)[0].name;
    let nameB = this.dictionaries[prop].filter(item => item.id === propB)[0].name;
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
  }

  simpleComparator(propA, propB, rowA, rowB) {
    if (rowA.age < rowB.age) return -1;
    if (rowA.age > rowB.age) return 1;
  }
}
