import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";

import { AuthenticationService } from "./authentication.service";

@Injectable({ providedIn: "root" })
export class secondAbilityGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;

    if (
      currentUser &&
      currentUser.abilities &&
      currentUser.abilities.includes(2)
    ) {
      return true;
    }

    this.router.navigate(["/"], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
