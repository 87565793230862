import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from '@angular/material/tree';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { forkJoin, fromEvent, Observable, Subscription } from 'rxjs';
import 'rxjs/add/operator/debounceTime';
import { AuthenticationService } from '../../services/authentication.service';
import { AppService } from '../../services/app-service';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '../../../model';
import { MY_FORMATS_CUSTOM } from '../../../model';
import { default as _moment, default as _rollupMoment } from 'moment';
import { FormControl, FormGroup } from '@angular/forms';

interface list {
  name: string;
  children?: list[];
  link: string;
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  link: string;
}

const moment = _rollupMoment || _moment;
@Component({
  selector: 'account',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.less'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS_CUSTOM }
  ]
})

export class AnalyticsComponent implements AfterViewInit {
  private indicators;
  private currentUser = this.authenticationService.currentUserValue;
  public roads = [];
  public territorialAdministrations = [];
  public dispatchAreas = [];
  public date: any;
  public departments = [];
  public currentApprovements = [];
  public indicatorApprovementsStruct = [];
  public modalData: any;
  public isModalOpen = false;
  private subscriptions: Subscription = new Subscription();
  public columns = [
    { name: 'indicator_id', title: 'Наименование показателя', dictionary: true  },
    { name: 'unit_id', title: 'Ед. изм.' , dictionary: true },
    { name: 'department_id', title: 'Орг. структура' , dictionary: true },
    { name: 'value_type_id', title: 'Тип значений', dictionary: true },
    { name: 'data_type_id', title: 'Тип данных', dictionary: true  },
    { name: 'metric_type_id', title: 'Метрика данных', dictionary: true  },
    { name: 'date_type_id', title: 'Тип периода', dictionary: true  },
    { name: 'adjustmentReason', title: 'Комментарий', input: true },
    { name: 'value', title: 'Значение', inputNum: true }
  ];
  public dictionaries = {};
  @ViewChild('tree', { static: false }) tree;
  selected = this.location.path();
  loading;
  private _transformer = (node: list, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      link: node.link
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    private _service: AppService,
    public location: Location,
    private authenticationService: AuthenticationService,
    private _spinner: NgxSpinnerService
  ) {
    let TREE_DATA: list[] = [];
    const currentUser = this.authenticationService.currentUserValue;

    if (
      currentUser &&
      currentUser.abilities &&
      currentUser.abilities.includes(1)
    ) {
      TREE_DATA.push({
        name: 'Управление привязками',
        link: '/link1',
        children: [
          { name: 'Просмотр и удаление привязок', link: '/link1' },
          { name: 'Привязка к орг. структуре', link: '/link2' },
          { name: 'Привязка к измерениям', link: '/link3' }
        ]
      });
    }
    if (
      currentUser &&
      currentUser.abilities &&
      currentUser.abilities.includes(2)
    ) {
      TREE_DATA.push(
        {
          name: 'Ввод значений показателей',
          link: '/link4'
        },
        {
          name: 'Просмотр истории ввода',
          link: '/link5'
        }
      );
    }
    if (
      currentUser &&
      currentUser.abilities &&
      currentUser.abilities.includes(1)
    ) {
      TREE_DATA.push(
        {
          name: 'Назначение ответственных',
          link: '/link7'
        },
        {
          name: 'Отчет по показателям',
          link: '/link6'
        }
      );
    }

    this._spinner.show();
    this.dataSource.data = TREE_DATA;

    const params = {};
    this.columns.forEach(item => {
      if (item.dictionary) {
        this.dictionaries[item.name] = {};
        if (item.name != 'department_id') {
          this.subscriptions.add(
            this._service.getDictionaryDBAPI(item.name).subscribe(data => {
              this.dictionaries[item.name] = data;
            })
          );
        }
      }
    });
    console.log(this.dictionaries);

    forkJoin(
    this._service.getDictionary('departmentId'),
    this._service.getDictionary('roadId'),
    this._service.getDictionary('territorialAdministrationId'),
    this._service.getDictionary('dispatchAreaId'),
    this._service.getDataBodyDBAPI('kpicol.indicator_approvements', params),
    this._service.getDataBodyDBAPI('kpicol.indicator_value', params)
    ).subscribe(([departments, roads, territorialAdministrations, dispatchAreas, approvements, values]) => {
        this.roads = roads;
        this.territorialAdministrations = territorialAdministrations;
        this.dispatchAreas = dispatchAreas;
        if (this.currentUser && this.currentUser.departments) {
          this.departments = [
            ...departments.filter(deprt => {
              if (deprt.id && this.currentUser.departments.includes(deprt.id)) {
                return deprt;
              } // отфильтрует полученные данные по массиву разрешенных департаментов
            })
          ];
        }
        this.currentApprovements = approvements;
        if (approvements.length) {
          const indicatorApprovements = approvements.map(approveRow => {
            let filtered = values.filter(valueRow =>
              valueRow.road_id == approveRow.road_id &&
              valueRow.territorial_administration_id == approveRow.territorial_administration_id &&
              valueRow.dispatch_area_id == approveRow.dispatch_area_id &&
              valueRow.metric_type_id == approveRow.metric_type_id &&
              valueRow.department_id == approveRow.department_id &&
              valueRow.date_type_id == approveRow.date_type_id &&
              valueRow.data_type_id == approveRow.data_type_id &&
              valueRow.unit_id == approveRow.unit_id &&
              valueRow.report_date == approveRow.report_date &&
              valueRow.value_type_id == approveRow.value_type_id &&
              valueRow.indicator_id == approveRow.indicator_id);
            if (filtered.length) {
              filtered = filtered.map(el => {
                el.approvals = typeof approveRow.approvals == 'string'
                  ? JSON.parse(approveRow.approvals).users
                  : approveRow.approvals.users;
                return el;
              });
            } else {
             return [approveRow];
            }
            return filtered;
          });
          indicatorApprovements.filter(el => el.length).map(indAppr => {
            if (this.dictionaries.hasOwnProperty('indicator_id')) {
              indAppr[0].indicator = this.dictionaries['indicator_id'].filter(el => el.id == indAppr[0].indicator_id)[0].name;
            }
            if (this.dictionaries.hasOwnProperty('metric_type_id')) {
              indAppr[0].metricType = this.dictionaries['metric_type_id'].filter(el => el.id == indAppr[0].metric_type_id)[0].name;
            }
            if (this.dictionaries.hasOwnProperty('date_type_id')) {
              indAppr[0].dateType = this.dictionaries['date_type_id'].filter(el => el.id == indAppr[0].date_type_id)[0].name;
            }
            if (this.dictionaries.hasOwnProperty('data_type_id')) {
              indAppr[0].dataType = this.dictionaries['data_type_id'].filter(el => el.id == indAppr[0].data_type_id)[0].name;
            }
            if (this.dictionaries.hasOwnProperty('value_type_id')) {
              indAppr[0].valueType = this.dictionaries['value_type_id'].filter(el => el.id == indAppr[0].value_type_id)[0].name;
            }
            if (this.dictionaries.hasOwnProperty('unit_id')) {
              indAppr[0].unit = this.dictionaries['unit_id'].filter(el => el.id == indAppr[0].unit_id)[0].name;
            }
            indAppr[0].report_date = moment(indAppr[0].report_date).format(('DD.MM.YYYY'));
            if (indAppr[0].hasOwnProperty('approved')) {
              if (typeof indAppr[0].approved == 'string') {
                indAppr[0].approved = JSON.parse(indAppr[0].approved);
              }
              indAppr[0].voted = Object.keys(indAppr[0].approved.by).map((username) => {
                const user = { username: '', info: {} };
                user.username = username;
                user.info = typeof indAppr[0].approved == 'string'
                  ? JSON.parse(indAppr[0].approved).by[username]
                  : indAppr[0].approved.by[username];
                return user;
              });
            } else {
              indAppr[0].voted = [];
              indAppr[0].approvals = typeof indAppr[0].approvals == 'string'
                ? JSON.parse(indAppr[0].approvals).users
                : indAppr[0].approvals.users;
            }
            const road = this.roads.filter(el => el.id == indAppr[0].road_id)[0].name;
            if (indAppr[0].territorial_administration_id !== null) {
              const tu = this.territorialAdministrations.filter(el => el.id == indAppr[0].territorial_administration_id)[0].name;
              indAppr[0].orgStruct = road + '; ' + tu;
            } else if (indAppr[0].dispatch_area_id !== null) {
              const du = this.dispatchAreas.filter(el => el.id == indAppr[0].dispatch_area_id)[0].name;
              indAppr[0].orgStruct = road + '; ' + du;
            } else {
              indAppr[0].orgStruct = road;
            }
            indAppr[0].status = 0;
            if (indAppr[0].voted.length) {
              if (indAppr[0].voted.length == indAppr[0].approvals.length) {
                if (indAppr[0].voted.filter(el => !el.info.decision).length) {
                  indAppr[0].status = 3;
                } else {
                  indAppr[0].status = 2;
                }
              } else {
                indAppr[0].status = 1;
              }
            } else {
              indAppr[0].status = 0;
            }
          });
          this.indicatorApprovementsStruct = indicatorApprovements
            .filter(el => el.length)
            .map(el => el[0])
            .sort((elA, elB) => elB.id - elA.id);
          console.log(this.indicatorApprovementsStruct);
        }
      });
  }

  public openModal = (object) => {
    this.modalData = object;
    this.isModalOpen = true;
  }
  public closeModal = () => {
    this.modalData = null;
    this.isModalOpen = false;
  }
  public getUnvotedArray = (users, voted) => {
    const unvoted = users.filter(elem => !voted.filter(el => el.username === elem.username).length);
    console.log(users, voted, unvoted);
    return unvoted;
  }
  public onReportClick = () => {
    const date = new Date();
    const year: string = date.getFullYear().toString();
    const title = encodeURI('Отчет по показателям за ' + year + ' год');
    const url = '/yarg/generate?report=yr&params[title]=' + title + '&params[year]=' + year;
    window.open(url);
  }
  ngAfterViewInit() {
    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data.some(item => {
        if (item.children) {
          this.treeControl.expandAll();
        }
      });
    }
  }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  setSelected(name: string) {
    this.selected = name;
  }

  logout() {
    this.loading = true;
    this.authenticationService.logout();
  }
}
