import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import * as mock from './mock.json';
import _ from 'lodash';
import { forkJoin, Subscription } from 'rxjs';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { AppService } from '../../services/app-service';
import { MY_FORMATS } from './../../../model';
import { ResponsibilityService } from './responsibility.service';

import { CustomDPHeaderComponent } from './../custom-dpheader/custom-dpheader.component';
import { AuthenticationService } from '../../services/authentication.service';
import { MatRadioGroup } from '@angular/material';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'responsibility',
  templateUrl: './responsibility.component.html',
  styleUrls: ['./responsibility.component.less'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class ResponsibilityComponent {
  profileForm = new FormGroup({
    'category': new FormControl('')
  });
  exampleHeader = CustomDPHeaderComponent; // кастомная верхушка календаря
  private currentUser = this.authenticationService.currentUserValue;

  public rows = []; // содержит изменения в визуальных строках, полный комплект как в source_rows.
  public source_rows = []; // неизменяемые строки, обновляются только после сохранения.
  public filtered_rows = []; // строки в таблице, часть от rows. Передача по ссылке.
  public loading: boolean = true;
  public savingData = false;
  public isReadOnly = true;

  public columns = [
    { name: 'indicator', title: 'Наименование показателя' },
    { name: 'unitType', title: 'Ед. изм.' },
    { name: 'orgStruct', title: 'Орг. структура' },
    { name: 'valueTypeId', title: 'Тип значений', dictionary: true },
    { name: 'dataType', title: 'Тип данных' },
    { name: 'metricType', title: 'Метрика данных' },
    { name: 'dateType', title: 'Тип периода' },
    { name: 'responsibles', title: 'Ответственные', input: true },
    // { name: 'adjustmentReason', title: 'Причина корректировки', input: true },
    // { name: 'value', title: 'Значение', inputNum: true }
  ];

  public currentPageLimit = 5;
  public pageCondition = false;
  public pageLimitOptions = [
    { value: 5, viewValue: '5' },
    { value: 10, viewValue: '10' },
    { value: 20, viewValue: '20' },
    { value: 100, viewValue: '100' }
  ];

  public dictionaries = {};
  public periodFilter = undefined;
  public selectPeriods = [];
  public period = 'year';
  public departments = [];
  public departmentId = 0;
  public orgStructFilter;
  public selectStructures = [
    { id: 'all', name: 'Все' },
    { id: 'roads', name: 'Дороги' },
    { id: 'territorialAdministration', name: 'Ту' },
    { id: 'dispatchArea', name: 'Ду' }
  ];
  private tableFilterInput = '';
  public convertedDate = '';
  public date: FormControl = new FormControl(moment());
  private subscriptions: Subscription = new Subscription();
  public users = [];
  public selectedApprovements = [];
  public isReadyToSave = false;
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(
    private _service: AppService,
    private _spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public inputService: ResponsibilityService,
    private authenticationService: AuthenticationService
  ) {
    this._spinner.show();
    this._service.getDictionary('departmentId').subscribe(result => {
      if (this.currentUser && this.currentUser.departments) {
        this.departments = [
          ...result.filter(deprt => {
            if (deprt.id && this.currentUser.departments.includes(deprt.id)) {
              return deprt;
            } // отфильтрует полученные данные по массиву разрешенных департаментов
          })
        ];
      }
      if (
        !(
          this.currentUser &&
          this.currentUser.abilities &&
          this.currentUser.abilities.includes(1)
        )
      ) {
        this.departmentId = this.departments.length ? this.departments[0].id : 1;
      }
    });
    this._service.getDictionary('dateTypeId').subscribe(result => {
      this.selectPeriods = result.filter(p => p.name !== 'Не задано');
      /*this.periodFilter =
        this.currentUser &&
        this.currentUser.abilities &&
        this.currentUser.abilities.includes(1)
          ? undefined
          : result[1];*/
      this.loadData();
    });
    this._service
      .getDataBodyDBAPI('adm.users', {})
      .subscribe(result => {
        result.map(user => {
          const userInfo = {id: undefined, username: undefined};
          userInfo.id = user.id;
          userInfo.username = user.username;
          this.users = [
            ...this.users,
            userInfo
          ];
        });
      });
    this.loading = false;
    // подписываемся на все словари, которые нам нужны в табличке
    this.columns.forEach(item => {
      if (item.dictionary) {
        this.dictionaries[item.name] = {};
        this.subscriptions.add(
          this._service.getDictionary(item.name).subscribe(data => {
            this.dictionaries[item.name] = data;
          })
        );
      }
    });
  }

  public getDictionaryData(name, id): string {
    let value;
    this.dictionaries[name].forEach(item => {
      if (item.id == id) {
        value = item.name;
      }
    });
    return value;
  }
  public chosenYearHandler(
    normalizedDate: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedDate.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.orgStructFilter = undefined;
  }
  public chosenMonthHandler(
    normalizedDate: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedDate.month());
    ctrlValue.year(normalizedDate.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.orgStructFilter = undefined;
  }
  public chosenDayHandler() {
    if (this.periodFilter.name === 'Квартал') {
      this.convertToQuarter();
    } else if (this.periodFilter.name === 'Неделя') {
      this.convertToWeek();
    }
    this.loadData();
    this.orgStructFilter = undefined;
  }
  public convertToQuarter() {
    const month = moment(this.date.value).month();
    switch (month) {
      case 0:
      case 1:
      case 2:
        this.date.setValue(
          moment(this.date.value)
            .month(0)
            .date(1)
        );
        break;

      case 3:
      case 4:
      case 5:
        this.date.setValue(
          moment(this.date.value)
            .month(3)
            .date(1)
        );
        break;

      case 6:
      case 7:
      case 8:
        this.date.setValue(
          moment(this.date.value)
            .month(6)
            .date(1)
        );
        break;

      default:
        this.date.setValue(
          moment(this.date.value)
            .month(9)
            .date(1)
        );
        break;
    }
  }
  public convertToWeek() {
    const week = moment(this.date.value).week();
    this.date.setValue(
      moment()
        .day('Monday')
        .week(week)
    );
  }
  public getConvertedDate() {
    let convertedDate;
    if (this.periodFilter.name === 'Квартал') {
      const month = moment(this.date.value).month();
      const quarterArray = [
        'I квартал',
        '',
        '',
        'II квартал',
        '',
        '',
        'III квартал',
        '',
        '',
        'IV квартал',
        '',
        ''
      ];
      convertedDate = `${quarterArray[month]} ${moment(this.date.value)
        .locale('ru')
        .format('YYYY')}`;
    } else if (this.period === 'month') {
      convertedDate = moment(this.date.value)
        .locale('ru')
        .format('LL');
    } else if (this.period === 'year') {
      convertedDate = moment(this.date.value)
        .locale('ru')
        .format('MMMM YYYY');
    } else if (this.period === 'multi-year') {
      convertedDate = moment(this.date.value)
        .locale('ru')
        .format('YYYY');
    }
    return convertedDate;
  }
  public PageSelectionChange(value, rowCount) {
    this.currentPageLimit = value;
    if (value >= rowCount) {
      this.pageCondition = true;
    } else {
      this.pageCondition = false;
    }
  }
  public UsersSelectChange(row) {
    const departmentId = this.departmentId;
    const period = this.periodFilter.name;
    let params = {
      reportDate: undefined,
      dateTypeId: undefined,
      departmentId: this.departmentId
    };
    params.dateTypeId = this.selectPeriods.filter(
      item => item.name === period
    )[0].id;

    let newDate = moment(this.date.value);
    if (period === 'Месяц') {
      newDate = moment(this.date.value).date(1);
    } else if (period === 'Год') {
      newDate = moment(this.date.value)
        .month(0)
        .date(1);
    }
    params.reportDate = newDate.format('YYYY-MM-DD');
    const approveRow = {
      adjustment_reason: row.adjustmentReason,
      data_type_id:      row.dataTypeId,
      date_type_id:      row.dateTypeId,
      department_id:     departmentId,
      dispatch_area_id:  row.dispatchAreaId,
      indicator_id:      row.indicatorId,
      metric_type_id:    row.metricTypeId,
      road_id:           row.roadId,
      territorial_administration_id: row.territorialAdministrationId,
      unit_id:           row.unitTypeId,
      approvals:         { users: row.responsibles },
      value_type_id:     row.valueTypeId,
      report_date:       params.reportDate
    };
    const filteredApprovements = this.selectedApprovements.filter(approvementRow =>
      approvementRow.department_id == approveRow.department_id &&
      approvementRow.date_type_id == approveRow.date_type_id &&
      approvementRow.data_type_id == approveRow.data_type_id &&
      approvementRow.report_date == approveRow.report_date &&
      approvementRow.value_type_id == approveRow.value_type_id &&
      approvementRow.road_id == approveRow.road_id &&
      approvementRow.indicator_id == approveRow.indicator_id &&
      approvementRow.territorial_administration_id == approveRow.territorial_administration_id &&
      approvementRow.dispatch_area_id == approveRow.dispatch_area_id
    );
    if (filteredApprovements.length) {
      this.selectedApprovements.map((approvementRow, index) => {
        if (approvementRow.department_id == approveRow.department_id &&
          approvementRow.date_type_id == approveRow.date_type_id &&
          approvementRow.data_type_id == approveRow.data_type_id &&
          approvementRow.report_date == approveRow.report_date &&
          approvementRow.value_type_id == approveRow.value_type_id &&
          approvementRow.road_id == approveRow.road_id &&
          approvementRow.indicator_id == approveRow.indicator_id &&
          approvementRow.territorial_administration_id == approveRow.territorial_administration_id &&
          approvementRow.dispatch_area_id == approveRow.dispatch_area_id) {
          if (row.responsibles.length) {
            approvementRow.approvals = approveRow.approvals;
          } else {
            if (approvementRow.hasOwnProperty('id')) {
              approvementRow.approvals = approveRow.approvals;
            } else {
              this.selectedApprovements.splice(index, 1);
            }
          }
        }
        return approvementRow;
      });
    } else {
      if (row.responsibles.length) {
        this.selectedApprovements = [
          ...this.selectedApprovements,
          approveRow
        ];
      }
    }
    this.isReadyToSave = true;
    console.log('change', row, approveRow, this.selectedApprovements);
  }

  public departmentSelectChange(value) {
    this.departmentId = value;
    this.loadData();
  }
  public loadData() {
    if (this.departmentId && this.periodFilter) {
      const params = {
        reportDate: undefined,
        dateTypeId: undefined,
        departmentId: this.departmentId
      };
      const paramsDB = {
        report_date: undefined,
        date_type_id: undefined,
        department_id: this.departmentId
      };
      const period = this.periodFilter.name;
      if (period !== 'Не задано') {
        params.dateTypeId = this.selectPeriods.filter(
          item => item.name === period
        )[0].id;
        paramsDB.date_type_id = this.selectPeriods.filter(
          item => item.name === period
        )[0].id;

        let newDate = moment(this.date.value);
        if (period === 'Месяц') {
          newDate = moment(this.date.value).date(1);
        } else if (period === 'Год') {
          newDate = moment(this.date.value)
            .month(0)
            .date(1);
        }

        params.reportDate = newDate.format('YYYY-MM-DD');
        paramsDB.report_date = newDate.format('YYYY-MM-DD');

        this.loading = true;
        forkJoin(
        this._service.getDataBody('/indicator-value', params),
        this._service.getDataBodyDBAPI('kpicol.indicator_approvements', paramsDB),
        ).subscribe(([
          result,
          approvements
          ]) => {
          if (approvements && approvements.length) {
            approvements.map(approve => {
              const filteredApprovements = this.selectedApprovements.filter(approvementRow =>
                approvementRow.department_id === approve.department_id &&
                approvementRow.date_type_id === approve.date_type_id &&
                approvementRow.data_type_id === approve.data_type_id &&
                approvementRow.report_date === approve.report_date &&
                approvementRow.road_id === approve.road_id &&
                approvementRow.value_type_id === approve.value_type_id &&
                approvementRow.indicator_id === approve.indicator_id &&
                approvementRow.territorial_administration_id === approve.territorial_administration_id &&
                approvementRow.dispatch_area_id === approve.dispatch_area_id
              );
              if (!filteredApprovements.length) {
                this.selectedApprovements = [
                  ...this.selectedApprovements,
                  approve
                ];
              }
            });
          }
          console.log('init', this.selectedApprovements);
          this.loading = false;

          let buffer;
          if (this.currentUser && this.currentUser.roads) {
            buffer = result.filter(item => {
              if (
                item.roadId &&
                this.currentUser.roads &&
                this.currentUser.roads.includes(item.roadId)
              ) {
                return item;
              } // отфильтрует полученные данные по массиву разрешенных дорог
            });
            buffer = buffer.map(el => {
              el.responsibles = this.getCurrentRowSelectedUsers(el, params.reportDate);
              return el;
            });
            this.rows = JSON.parse(JSON.stringify(buffer));
            this.source_rows = JSON.parse(JSON.stringify(buffer));
          }
          this.runFilter();
          });
      }
    }
  }

  public getCurrentRowSelectedUsers(item, reportDate) {
    const filteredApprovements = this.selectedApprovements.filter(approvementRow =>
      approvementRow.date_type_id === item.dateTypeId &&
      approvementRow.data_type_id === item.dataTypeId &&
      approvementRow.metric_type_id === item.metricTypeId &&
      approvementRow.unit_id === item.unitTypeId &&
      approvementRow.road_id === item.roadId &&
      approvementRow.report_date === reportDate &&
      approvementRow.indicator_id === item.indicatorId &&
      approvementRow.value_type_id === item.valueTypeId &&
      approvementRow.territorial_administration_id === item.territorialAdministrationId &&
      approvementRow.dispatch_area_id === item.dispatchAreaId
    );
    if (filteredApprovements.length) {
      const userList = typeof filteredApprovements[0].approvals === 'string'
       ? JSON.parse(filteredApprovements[0].approvals).users
       : filteredApprovements[0].approvals.users;
      const filteredusers = this.users.filter(user => {
        if (userList.filter(usr => usr.id === user.id).length) {
          return true;
        } else {
          return false;
        }
      });
      return filteredusers;
    } else {
      return [];
    }
  }
  public departmentChange() {
    this.loadData();
    this.orgStructFilter = undefined;
    this.table.offset = 0;
  }

  // одинаков
  public updateFilter(event) {
    this.tableFilterInput = event.target.value.toLowerCase();
    this.runFilter();
  }

  public selectionChange(event, key) {
    this.date.setValue(moment());
    if (!_.isEmpty(this.periodFilter)) {
      this._service.setCustomPeriod(this.periodFilter.name);
      switch (this.periodFilter.name) {
        case 'Сутки':
          this.period = 'month';
          break;

        case 'Неделя':
          this.period = 'month';
          this.convertToWeek();
          break;

        case 'Месяц':
          this.period = 'year';
          break;

        case 'Квартал':
          this.period = 'year';
          this.convertToQuarter();
          break;

        case 'Год':
          this.period = 'multi-year';
          break;

        default:
          this.period = 'multi-year';
          break;
      }
    } else {
      this.period = 'multi-year';
    }
    this.table.offset = 0;
    this.loadData();
    this.orgStructFilter = undefined;
  }

  public saveData() {
    this.savingData = true;
    const periodFilter = this.periodFilter ? this.periodFilter.name : null;
    if (!this.departmentId || !periodFilter) {
      this.savingData = false;
      if (!this.departmentId) {
        this.showMessage('Выберите департамент', 'error');
      } else {
        this.showMessage('Выберите тип периода', 'error');
      }
      return;
    }
    if (!this.isReadyToSave || !this.selectedApprovements.length) {
      this.showMessage('Нет данных для сохранения');
      this.savingData = false;
      return;
    }

    const newApprovements = this.selectedApprovements.filter(approvement => {
      let users = [];
      if (typeof approvement.approvals == 'string') {
        users = JSON.parse(approvement.approvals).users;
      } else {
        users =  approvement.approvals.users;
      }
      return !approvement.hasOwnProperty('id') && users.length;
    });
    const updateApprovements = this.selectedApprovements.filter(approvement => {
      let users = [];
      if (typeof approvement.approvals == 'string') {
        users = JSON.parse(approvement.approvals).users;
      } else {
        users =  approvement.approvals.users;
      }
      return approvement.hasOwnProperty('id') && users.length;
    });
    const deleteApprovements = this.selectedApprovements.filter(approvement => {
      let users = [];
      if (typeof approvement.approvals == 'string') {
        users = JSON.parse(approvement.approvals).users;
      } else {
        users =  approvement.approvals.users;
      }
      return approvement.hasOwnProperty('id') && !users.length;
    });
    console.log(this.selectedApprovements, 'new', newApprovements, 'update', updateApprovements, 'delete', deleteApprovements);

    const mode = newApprovements.length && updateApprovements.length && deleteApprovements.length ?
      'complex' : newApprovements.length && !updateApprovements.length && !deleteApprovements.length ?
      'new' : !newApprovements.length && updateApprovements.length && !deleteApprovements.length ?
      'update' : !newApprovements.length && !updateApprovements.length && deleteApprovements.length ?
      'delete' : newApprovements.length && updateApprovements.length && !deleteApprovements.length ?
      'new-update' : newApprovements.length && !updateApprovements.length && deleteApprovements.length ?
      'new-delete' : !newApprovements.length && updateApprovements.length && deleteApprovements.length ?
      'update-delete' : 'default';
    switch (mode) {
      case 'new':
        this._service
          .postDataDBAPI('kpicol.indicator_approvements', newApprovements)
          .subscribe(result => {
            this.savingData = false;
            this.isReadyToSave = false;
            if (result !== 'error') {
              this.selectedApprovements = [];
              this.loadData();
              this.showMessage(
                'Ввод ответственных за показатели успешно сохранен',
                'success'
              );
            } else {
              this.savingData = false;
              this.isReadyToSave = false;
              this.showMessage(
                'Ошибка при сохранении данных',
                'error'
              );
            }
          });
        break;
      case 'update':
        updateApprovements.map((updateApprovement, index) => {
          this._service
            .putDataDBAPI('kpicol.indicator_approvements/' + updateApprovement.id, updateApprovement)
            .subscribe(result => {
                if (result !== 'error') {
                  if (index == updateApprovements.length - 1) {
                    this.savingData = false;
                    this.isReadyToSave = false;
                    this.selectedApprovements = [];
                    this.loadData();
                    this.showMessage(
                      'Ввод ответственных за показатели успешно сохранен',
                      'success'
                    );
                  }
                } else {
                  this.savingData = false;
                  this.isReadyToSave = false;
                  this.showMessage(
                    'Ошибка при сохранении данных',
                    'error'
                  );
                }
            });
        });
        break;
      case 'delete':
        deleteApprovements.map((deleteApprovement, index) => {
          this._service
            .removeDataDBAPI('kpicol.indicator_approvements/' + deleteApprovement.id, deleteApprovement.id)
            .subscribe(result => {
                if (result !== 'error') {
                  if (index == deleteApprovements.length - 1) {
                    this.savingData = false;
                    this.isReadyToSave = false;
                    this.selectedApprovements = [];
                    this.loadData();
                    this.showMessage(
                      'Ввод ответственных за показатели успешно сохранен',
                      'success'
                    );
                  }
                } else {
                  this.savingData = false;
                  this.isReadyToSave = false;
                  this.showMessage(
                    'Ошибка при сохранении данных',
                    'error'
                  );
                }
            });
        });
        break;
      case 'complex':
        this._service
          .postDataDBAPI('kpicol.indicator_approvements', newApprovements)
          .subscribe(resultPost => {
            if (resultPost !== 'error') {
              updateApprovements.map((updateApprovement, indexUpdate) => {
                this._service
                  .putDataDBAPI('kpicol.indicator_approvements/' + updateApprovement.id, updateApprovement)
                  .subscribe(resultPut => {
                    if (resultPut !== 'error') {
                      if (indexUpdate == updateApprovements.length - 1) {
                        deleteApprovements.map((deleteApprovement, indexDelete) => {
                          this._service
                            .removeDataDBAPI('kpicol.indicator_approvements/' + deleteApprovement.id, deleteApprovement.id)
                            .subscribe(result => {
                                if (result !== 'error') {
                                  if (indexDelete == deleteApprovements.length - 1) {
                                    this.savingData = false;
                                    this.isReadyToSave = false;
                                    this.selectedApprovements = [];
                                    this.loadData();
                                    this.showMessage(
                                      'Ввод ответственных за показатели успешно сохранен',
                                      'success'
                                    );
                                  }
                                } else {
                                  this.savingData = false;
                                  this.isReadyToSave = false;
                                  this.showMessage(
                                    'Ошибка при сохранении данных',
                                    'error'
                                  );
                                }
                            });
                        });
                      }
                    } else {
                      this.savingData = false;
                      this.isReadyToSave = false;
                      this.showMessage(
                        'Ошибка при сохранении данных',
                        'error'
                      );
                    }
                  });
              });
            } else {
              this.savingData = false;
              this.isReadyToSave = false;
              this.showMessage(
                'Ошибка при сохранении данных',
                'error'
              );
            }
          });
        break;
      case 'new-update':
        this._service
          .postDataDBAPI('kpicol.indicator_approvements', newApprovements)
          .subscribe(resultPost => {
            if (resultPost !== 'error') {
              updateApprovements.map((updateApprovement, indexUpdate) => {
                this._service
                  .putDataDBAPI('kpicol.indicator_approvements/' + updateApprovement.id, updateApprovement)
                  .subscribe(resultPut => {
                    if (resultPut !== 'error') {
                      if (indexUpdate == updateApprovements.length - 1) {
                        this.savingData = false;
                        this.isReadyToSave = false;
                        this.selectedApprovements = [];
                        this.loadData();
                        this.showMessage(
                          'Ввод ответственных за показатели успешно сохранен',
                          'success'
                        );
                      }
                    } else {
                      this.savingData = false;
                      this.isReadyToSave = false;
                      this.showMessage(
                        'Ошибка при сохранении данных',
                        'error'
                      );
                    }
                  });
              });
            } else {
              this.savingData = false;
              this.isReadyToSave = false;
              this.showMessage(
                'Ошибка при сохранении данных',
                'error'
              );
            }
          });
        break;
      case 'new-delete':
        this._service
          .postDataDBAPI('kpicol.indicator_approvements', newApprovements)
          .subscribe(resultPost => {
            if (resultPost !== 'error') {
              deleteApprovements.map((deleteApprovement, indexDelete) => {
                this._service
                  .removeDataDBAPI('kpicol.indicator_approvements/' + deleteApprovement.id, deleteApprovement.id)
                  .subscribe(result => {
                      if (result !== 'error') {
                        if (indexDelete == deleteApprovements.length - 1) {
                          this.savingData = false;
                          this.isReadyToSave = false;
                          this.selectedApprovements = [];
                          this.loadData();
                          this.showMessage(
                            'Ввод ответственных за показатели успешно сохранен',
                            'success'
                          );
                        }
                      } else {
                        this.savingData = false;
                        this.isReadyToSave = false;
                        this.showMessage(
                          'Ошибка при сохранении данных',
                          'error'
                        );
                      }
                  });
              });
            } else {
              this.savingData = false;
              this.isReadyToSave = false;
              this.showMessage(
                'Ошибка при сохранении данных',
                'error'
              );
            }
          });
        break;
      case 'update-delete':
        updateApprovements.map((updateApprovement, indexUpdate) => {
          this._service
            .putDataDBAPI('kpicol.indicator_approvements/' + updateApprovement.id, updateApprovement)
            .subscribe(resultPut => {
              if (resultPut !== 'error') {
                if (indexUpdate == updateApprovements.length - 1) {
                  deleteApprovements.map((deleteApprovement, indexDelete) => {
                    this._service
                      .removeDataDBAPI('kpicol.indicator_approvements/' + deleteApprovement.id, deleteApprovement.id)
                      .subscribe(result => {
                          if (result !== 'error') {
                            if (indexDelete == deleteApprovements.length - 1) {
                              this.savingData = false;
                              this.isReadyToSave = false;
                              this.selectedApprovements = [];
                              this.loadData();
                              this.showMessage(
                                'Ввод ответственных за показатели успешно сохранен',
                                'success'
                              );
                            }
                          } else {
                            this.savingData = false;
                            this.isReadyToSave = false;
                            this.showMessage(
                              'Ошибка при сохранении данных',
                              'error'
                            );
                          }
                      });
                  });
                }
              } else {
                this.savingData = false;
                this.isReadyToSave = false;
                this.showMessage(
                  'Ошибка при сохранении данных',
                  'error'
                );
              }
            });
        });
        break;
      default:
        this.showMessage('Нет данных для сохранения');
        this.savingData = false;
        this.isReadyToSave = false;
        break;
    }
  }

  private showMessage(text = 'Стандартный текст сообщения', color = 'warning') {
    switch (color) {
      case 'success':
        this.toastr.success(text, '', { enableHtml: true, timeOut: 5000 });
        break;

      case 'error':
        this.toastr.error(text, '', {
          enableHtml: true,
          timeOut: 5000
        });
        break;
      case 'warning':
      default:
        this.toastr.warning(text, '', {
          enableHtml: true,
          timeOut: 5000
        });
        break;
    }
  }

  private formatSendingData(rows) {
    const periodFilter = this.periodFilter ? this.periodFilter.name : null;
    const date = this.date;

    let newDate = moment(date.value);
    if (periodFilter === 'Месяц') {
      newDate = moment(date.value).date(1);
    } else if (periodFilter === 'Год') {
      newDate = moment(date.value)
        .month(0)
        .date(1);
    }

    return rows.map(row => {
      return {
        adjustmentReason: row.adjustmentReason,
        dataTypeId: row.dataTypeId,
        dateTypeId: row.dateTypeId,
        departmentId: this.departmentId,
        dispatchAreaId: row.dispatchAreaId,
        indicatorId: row.indicatorId,
        metricTypeId: row.metricTypeId,
        reportDate: newDate.format('YYYY-MM-DD'),
        roadId: row.roadId,
        territorialAdministrationId: row.territorialAdministrationId,
        unitId: row.unitTypeId,
        value: row.value === '' ? null : row.value,
        valueTypeId: row.valueTypeId
      };
    });
  }

  private checkRowsBeforeSending() {
    return this.rows.filter((row, index) => {
      if (!_.isEqual(row, this.source_rows[index])) {
        const imutableRow = this.source_rows[index];

        const isReasonChanged =
          String(row.adjustmentReason) !== String(imutableRow.adjustmentReason);
        const isValueChanged = String(row.value) !== String(imutableRow.value);
        const isReasonEmptyString =
          row.adjustmentReason === null ||
          String(row.adjustmentReason).trim() === '';
        const isValueEmptyString =
          row.value === null || String(row.value).trim() === '';

        const isNew = !imutableRow.value;
        const newText = isNew ? 'new' : 'old';

        const isJustSeparatorChanged =
          row.value.replace(',', '.') === String(imutableRow.value) ||
          row.value.replace('.', ',') === String(imutableRow.value);

        const switchCondition =
          '' + isReasonChanged + '-' + isValueChanged + '|' + newText;

        switch (switchCondition) {
          case 'true-false|new':
            if (!isReasonEmptyString) {
              this.showMessage(
                'Нет данных для сохранения.<br>Пустое значение.'
              );
              throw new Error('true-false|new');
            }
            break;

          case 'false-true|new':
            if (!isValueEmptyString && !isJustSeparatorChanged) {
              return true;
            }
            break;

          case 'true-true|new':
            if (
              !isReasonEmptyString &&
              !isValueEmptyString &&
              !isJustSeparatorChanged
            ) {
              return true;
            } else if (
              isReasonEmptyString &&
              !isValueEmptyString &&
              !isJustSeparatorChanged
            ) {
              return true;
            } else if (
              !isReasonEmptyString &&
              isValueEmptyString &&
              !isJustSeparatorChanged
            ) {
              this.showMessage(
                'Нет данных для сохранения.<br>Пустое значение.'
              );
              throw new Error('true-true|new');
            }
            break;

          case 'true-false|old':
            this.showMessage(
              'Нет данных для сохранения.<br>Значение не изменено.'
            );
            throw new Error('true-false|old');
            break;

          case 'false-true|old':
            if (!isJustSeparatorChanged) {
              this.showMessage('Не изменено поле "причина корректировки"');
              throw new Error('false-true|old');
            }
            break;

          case 'true-true|old':
            if (
              !isReasonEmptyString &&
              !isValueEmptyString &&
              !isJustSeparatorChanged
            ) {
              return true;
            } else if (isReasonEmptyString && !isJustSeparatorChanged) {
              this.showMessage('Не указано поле "причина корректировки"');
            } else if (!isJustSeparatorChanged) {
              return true;
            }
            break;

          case 'false-false|old':
          case 'false-false|new':
            break;

          default:
            this.showMessage('Непредвиденная ситуация.', 'error');
            throw new Error('true-true|old');
            break;
        }
      }
    });
  }

  public runFilter() {
    if (!this.source_rows.length) {
      this.filtered_rows = [];
      return [];
    }
    this.table.offset = 0;

    let buffer = this.rows;
    const params = {
      reportDate: undefined,
      dateTypeId: undefined,
      departmentId: this.departmentId
    };
    const period = this.periodFilter.name;
    params.dateTypeId = this.selectPeriods.filter(
      item => item.name === period
    )[0].id;

    let newDate = moment(this.date.value);
    if (period === 'Месяц') {
      newDate = moment(this.date.value).date(1);
    } else if (period === 'Год') {
      newDate = moment(this.date.value)
        .month(0)
        .date(1);
    }

    params.reportDate = newDate.format('YYYY-MM-DD');
    if (!_.isEmpty(this.orgStructFilter)) {
      const filter = this.orgStructFilter.id;
      buffer = buffer.filter(item => {
        if (
          filter === 'all' ||
          (filter === 'roads' &&
            item.dispatchAreaId === null &&
            item.territorialAdministrationId === null) ||
          (filter === 'territorialAdministration' &&
            item.territorialAdministrationId !== null) ||
          (filter === 'dispatchArea' && item.dispatchAreaId !== null)
        ) {
          return true;
        }
      });
    }
    buffer = buffer.map(el => {
      el.responsibles = this.getCurrentRowSelectedUsers(el, params.reportDate);
      return el;
    });
    const tableFilterInput = this.tableFilterInput;
    const colsAmt = this.columns.length;
    const keys = this.columns.map(item => item.name);

    this.filtered_rows = buffer.filter((item) => {
      for (let i = 0; i < colsAmt; i++) {
        // valueTypeId
        if (item[keys[i]]) {
          if (
            (keys[i] === 'valueTypeId' &&
              item.valueType
                .toString()
                .toLowerCase()
                .indexOf(tableFilterInput) !== -1) ||
            !tableFilterInput
          ) {
            return true;
          } else if (
            item[keys[i]]
              .toString()
              .toLowerCase()
              .indexOf(tableFilterInput) !== -1 ||
            !tableFilterInput
          ) {
            return true;
          }
        }
      }
    });
    this.filtered_rows = this.filtered_rows.map(el => {
      el.responsibles = this.getCurrentRowSelectedUsers(el, params.reportDate);
      return el;
    });
  }
}
