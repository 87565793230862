import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { BindingOrganizationalChartComponent } from "./components/binding-organizational-chart/binding-organizational-chart.component";
import { BindingAdjustmentComponent } from "./components/binding-adjustment/binding-adjustment.component";
import { BindingIndicatorComponent } from "./components/binding-indicators/binding-indicators.component";
import { BindingInputComponent } from "./components/binding-input/binding-input.component";
import { BindingInputHistoryComponent } from "./components/binding-input-history/binding-input-history.component";
import { EmptyComponent } from "./empty";
import { HomeComponent } from "./components/home/home.component";
import { AccountComponent } from "./components/account/account.component";
import { AnalyticsComponent } from "./components/analytics/analytics.component";
import { ApprovementComponent } from "./components/approvement/approvement.component";
import { ResponsibilityComponent } from './components/responsibility/responsibility.component';
import { PersonalPageComponent } from "./components/personalPage/personalPage.component";

import { AuthGuard, firstAbilityGuard, secondAbilityGuard } from "./services";

const appRoutes: Routes = [
  {
    path: "link1",
    component: BindingAdjustmentComponent,
    canActivate: [firstAbilityGuard]
  },
  {
    path: "link2",
    component: BindingOrganizationalChartComponent,
    canActivate: [firstAbilityGuard]
  },
  {
    path: "link3",
    component: BindingIndicatorComponent,
    canActivate: [firstAbilityGuard]
  },
  {
    path: "link4",
    component: BindingInputComponent,
    canActivate: [secondAbilityGuard]
  },
  {
    path: "link5",
    component: BindingInputHistoryComponent,
    canActivate: [secondAbilityGuard]
  },
  {
    path: "link6",
    component: AnalyticsComponent,
    canActivate: [firstAbilityGuard]
  },
  {
    path: "link7",
    component: ResponsibilityComponent,
    canActivate: [firstAbilityGuard]
  },
  {
    path: "user",
    component: ApprovementComponent, // PersonalPageComponent,
    // canActivate: [firstAbilityGuard]
  },
  { path: "empty", component: EmptyComponent },
  { path: "", redirectTo: "/empty", pathMatch: "full" }
];

const authRoutes: Routes = [
  {
    path: "",
    component: HomeComponent,
    children: appRoutes,
    canActivate: [AuthGuard]
  },
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [RouterModule.forRoot(authRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
