import { Component, ViewChild, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';

import { DatatableComponent } from '@swimlane/ngx-datatable';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

import * as mock from './mock.json';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { AppService } from '../../services/app-service';
import { MY_FORMATS } from './../../../model';

import { CustomDPHeaderComponent } from './../custom-dpheader/custom-dpheader.component';
import { AuthenticationService } from '../../services/authentication.service';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'binding-input-history',
  templateUrl: './binding-input-history.component.html',
  styleUrls: ['./binding-input-history.component.less'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class BindingInputHistoryComponent implements OnDestroy {
  exampleHeader = CustomDPHeaderComponent;
  private currentUser = this.authenticationService.currentUserValue;

  rows = [];
  tableFilterInput = '';
  columns = [
    { name: 'indicatorCode', title: 'Код показателя' },
    { name: 'indicator', title: 'Описание' },
    { name: 'unitType', title: 'Единица измерения' },
    { name: 'department', title: 'Департамент' },
    { name: 'orgStruct', title: 'Оргструктура' },
    { name: 'valueType', title: 'Тип значений' },
    { name: 'dataType', title: 'Тип данных' },
    { name: 'metricType', title: 'Метрика данных' },
    { name: 'dateType', title: 'Тип периода' },
    { name: 'timestamp', title: 'Дата и время ввода' },
    { name: 'reportDate', title: 'Отчетная дата' },
    { name: 'value', title: 'Текущее значение' },
    { name: 'prevValue', title: 'Предыдущее значение' },
    { name: 'username', title: 'Пользователь' },
    { name: 'adjustmentReason', title: 'Причина корректировки' }
  ];

  public dictionaries = {
    departmentId: [],
    dateTypeId: []
  };
  old_row = [];
  loading: boolean = false;
  subscription = new Subscription();

  pageLimitOptions = [
    { value: 5, viewValue: '5' },
    { value: 10, viewValue: '10' },
    { value: 20, viewValue: '20' },
    { value: 100, viewValue: '100' }
  ];

  currentPageLimit = 5;
  pageCondition = false;
  public period = 'year';
  public departmentId: number;
  public dateTypeId: number = undefined;

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  public date = new FormControl(moment());

  public isShowSendButton: boolean;

  constructor(
    private _service: AppService,
    private _spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService
  ) {
    if (
      this.currentUser &&
      this.currentUser.abilities &&
      this.currentUser.abilities.includes(3)
    ) {
      this.isShowSendButton = true;
    } else {
      this.isShowSendButton = false;
    }

    this._spinner.show();
    // подписываемся на все словари, которые нам нужны в селектах
    Object.keys(this.dictionaries).map(item => {
      this._service.getDictionary(item).subscribe(data => {
        if (item === 'departmentId') {
          if (this.currentUser && this.currentUser.departments) {
            this.dictionaries[item] = [
              ...data.filter(deprt => {
                if (
                  deprt.id &&
                  this.currentUser.departments.includes(deprt.id)
                ) {
                  return deprt;
                } // отфильтрует полученные данные по массиву разрешенных департаментов
              })
            ];
          }
        } else {
          this.dictionaries[item] = data.filter(p => p.name !== 'Не задано');
        }
        if (
          !this[item] &&
          item !== 'dateTypeId' &&
          !(
            this.currentUser &&
            this.currentUser.abilities &&
            this.currentUser.abilities.includes(1)
          )
        ) {
          this[item] = this.dictionaries[item][0].id; // установит значение выбранного департамента
        }
        this.getData();
      });
    });
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  chosenMonthHandler(
    normalizedMonth: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedMonth.year());
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    datepicker.close();
  }

  public chosenDayHandler() {
    let dateTypeName = this.dictionaries.dateTypeId.filter(
      item => item.id === this.dateTypeId
    )[0].name;
    if (dateTypeName === 'Квартал') {
      this.convertToQuarter();
    } else if (dateTypeName === 'Неделя') {
      this.convertToWeek();
    }
    this.getData();
  }

  public getConvertedDate() {
    let convertedDate;
    if (this.dateTypeId === 2) {
      const month = moment(this.date.value).month();
      const quarterArray = [
        'I квартал',
        '',
        '',
        'II квартал',
        '',
        '',
        'III квартал',
        '',
        '',
        'IV квартал',
        '',
        ''
      ];
      convertedDate = `${quarterArray[month]} ${moment(this.date.value)
        .locale('ru')
        .format('YYYY')}`;
    } else if (this.period === 'month') {
      convertedDate = moment(this.date.value)
        .locale('ru')
        .format('LL');
    } else if (this.period === 'year') {
      convertedDate = moment(this.date.value)
        .locale('ru')
        .format('MMMM YYYY');
    } else if (this.period === 'multi-year') {
      convertedDate = moment(this.date.value)
        .locale('ru')
        .format('YYYY');
    }
    return convertedDate;
  }

  public PageSelectionChange(value, rowCount) {
    this.currentPageLimit = value;
    if (value >= rowCount) {
      this.pageCondition = true;
    } else {
      this.pageCondition = false;
    }
  }

  public selectionChange() {
    let dateTypeName = this.dictionaries.dateTypeId.filter(
      item => item.id === this.dateTypeId
    )[0].name;
    this._service.setCustomPeriod(dateTypeName);
    this.date.setValue(moment());
    switch (dateTypeName) {
      case 'Месяц':
        this.period = 'year';
        break;
      case 'Сутки':
        this.period = 'month';
        break;
      case 'Неделя':
        this.period = 'month';
        this.convertToWeek();
        break;
      case 'Год':
        this.period = 'multi-year';
        break;
      case 'Квартал':
        this.period = 'year';
        this.convertToQuarter();
        break;
      default:
        this.period = 'multi-year';
        break;
    }
  }

  public convertToWeek() {
    const week = moment(this.date.value).week();
    this.date.setValue(
      moment()
        .day('Monday')
        .week(week)
    );
  }

  public convertToQuarter() {
    const month = moment(this.date.value).month();
    switch (month) {
      case 0:
      case 1:
      case 2:
        this.date.setValue(
          moment(this.date.value)
            .month(0)
            .date(1)
        );
        break;

      case 3:
      case 4:
      case 5:
        this.date.setValue(
          moment(this.date.value)
            .month(3)
            .date(1)
        );
        break;

      case 6:
      case 7:
      case 8:
        this.date.setValue(
          moment(this.date.value)
            .month(6)
            .date(1)
        );
        break;

      default:
        this.date.setValue(
          moment(this.date.value)
            .month(9)
            .date(1)
        );
        break;
    }
  }

  public getData() {
    if (
      this.departmentId &&
      this.dateTypeId &&
      this.dictionaries.dateTypeId.length
    ) {
      let params = {
        reportDate: undefined,
        departmentId: this.departmentId,
        dateTypeId: this.dateTypeId
      };
      let dateTypeName = this.dictionaries.dateTypeId.filter(
        item => item.id === this.dateTypeId
      )[0].name;
      let newDate = moment(this.date.value);
      if (dateTypeName === 'Месяц') {
        newDate = moment(this.date.value).date(1);
      } else if (dateTypeName === 'Год') {
        newDate = moment(this.date.value)
          .month(0)
          .date(1);
      }
      params.reportDate = newDate.format('YYYY-MM-DD');

      this.loading = true;
      this.subscription.unsubscribe();
      this.subscription = this._service
        .getData('/history/manual', params)
        .subscribe(result => {
          let data = this.formatingData(result).filter(row => {
            if (
              row.roadId &&
              this.currentUser.roads &&
              this.currentUser.roads.includes(row.roadId)
            ) {
              return row;
            }
          }); // отфильтрует полученные данные по массиву разрешенных дорог
          this.rows = data;
          this.rows = [...this.rows];
          this.old_row = data;
          this.loading = false;
        });
    }
  }

  public getAdminData() {
    this.subscription.unsubscribe();
    this.subscription = this._service
      .postData('/exchange')
      .subscribe(result => {
        if (result != 'error')
          this.toastr.success('Данные успешно отправлены', '', {
            enableHtml: true,
            timeOut: 5000
          });
        this.loading = false;
      });
  }

  // одинаков
  public updateFilter(event) {
    this.tableFilterInput = event.target.value.toLowerCase();
    this.table.offset = 0;
  }

  private formatingData(array) {
    return array.map(item => {
      let datetime = moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss');
      item.timestamp = datetime;
      return item;
    });
  }

  filtered(table_rows) {
    if (table_rows.length) {
      let tableFilterInput = this.tableFilterInput;
      let colsAmt = this.columns.length;
      let keys = this.columns.map(item => item.name);
      return table_rows.filter(function(item) {
        for (let i = 0; i < colsAmt; i++) {
          if (
            (item[keys[i]] &&
              item[keys[i]]
                .toString()
                .toLowerCase()
                .indexOf(tableFilterInput) !== -1) ||
            !tableFilterInput
          ) {
            return true;
          }
        }
      });
    } else return [];
  }

  ngOnDestroy() {
    this._service.setCustomPeriod('');
  }
}
