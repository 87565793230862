import { Component, ViewChild } from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import _ from "lodash";
import { AppService } from "../../services/app-service";
import { NgxSpinnerService } from "ngx-spinner";
import { column, pageLimitOptions, tuList } from "../../../model/table";
import { forkJoin, Observable } from "rxjs";
import * as mock from "./test.json";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "binding-organizational-chart",
  templateUrl: "./binding-organizational-chart.component.html",
  styleUrls: ["./binding-organizational-chart.component.less"]
})
export class BindingOrganizationalChartComponent {
  rows = [];
  columns = [{ name: "road", title: "Департамент" }];
  oldColumns = [];
  columnsArray = [];
  old_row = [];
  loading: boolean = true;
  mainLoading: boolean = true;
  pageLimitOptions: pageLimitOptions[] = [
    { value: 10, viewValue: "10" },
    { value: 20, viewValue: "20" },
    { value: 50, viewValue: "50" },
    { value: 100, viewValue: "100" }
  ];
  currentPageLimit = 10;
  pageCondition = false;
  public savingData = false;

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  constructor(
    private _service: AppService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.spinner.show();

    // загружаем 3 справочника для состовления левой колонки-дерева, верхней колонки и заполнение данными
    forkJoin(
      this._service.getDictionary("roadId"),
      this._service.getDictionary("territorialAdministrationId"),
      this._service.getDictionary("dispatchAreaId"),
      this._service.getDictionary("indicatorId"),
      this._service.getData("/indicatorOrgStruct")
    ).subscribe(
      ([
        roads,
        territorialAdministrations,
        dispatchAreas,
        indicators,
        indicatorOrgStruct
      ]) => {
        indicators.map(item => {
          /*if (item.name.length >= 40) {
            let str = item.name.slice(0, 37) + "...";
            // по какой-то логике было именно так, но теперь это пометили как баг
            this.oldColumns.push({
              name: item.id,
              title: item.name,
              value: str
            });
          } else {*/
          this.oldColumns.push({
            name: item.id,
            title: item.name,
            value: item.name
          });
          /*}*/
        });
        this.columnsArray = this.oldColumns;

        roads.map(road => {
          // Добавляю первый уровень дерева - дороги
          const new_row = {
            road: road.name,
            roadId: road.id,
            treeStatus: "collapsed",
            selectAllRow: true,
            id: road.id
          };
          // тут берем данные из indicatorOrgStruct и подставляем в табличку
          let roadData =
            indicatorOrgStruct &&
            indicatorOrgStruct.filter(obj => obj.roadId === road.id);
          indicators.map(item => {
            new_row[item.id] =
              roadData &&
              roadData.length &&
              roadData[0].indicators.indexOf(item.id) != -1;
          });
          this.rows.push(new_row);

          // Добавляем второй уровень ТУ заголовок
          this.rows.push({
            road: "Подразделение",
            roadId: road.id + "Подразделение",
            treeStatus: "expanded",
            manager: road.id,
            selectAllPartRow: true
          });

          // Получаем все ТУ привязанные к дороге, добавляем в таблицу
          // ид в словарях не уникальные, все начинаются 1...10 и тд, поэтому добавляю буквы к roadId,
          // чтобы нормально строилссь дерево гридом
          territorialAdministrations
            .filter(tu => road.id == tu.roadCode)
            .map(keyTu => {
              const tu_row = {
                road: keyTu.name,
                id: keyTu.id,
                treeStatus: "disabled",
                manager: road.id + "Подразделение",
                roadId: keyTu.id + "Подразделение2",
                selectOneRow: true,
                mainRoadId: road.id,
                hideIcon: true
              };
              let tu =
                roadData &&
                roadData.length &&
                roadData[0].territorialAdministrations.filter(
                  obj => obj.id === keyTu.id
                );
              // тут берем данные из indicatorOrgStruct и подставляем в табличку
              indicators.map(item => {
                tu_row[item.id] =
                  tu &&
                  tu.length > 0 &&
                  tu[0].bindings &&
                  tu[0].bindings.indexOf(item.id) != -1;
              });
              this.rows.push(tu_row);
            });

          // Добавляем второй уровень ДУ заголовок
          this.rows.push({
            road: "Отдел",
            roadId: road.id + "Отдел",
            treeStatus: "expanded",
            manager: road.id,
            selectAllPartRow: true
          });

          // Получаем все ДУ привязанные к дороге, добавляем в таблицу
          // ид в словарях не уникальные, все начинаются 1...10 и тд, поэтому добавляю буквы к roadId,
          // чтобы нормально строилссь дерево гридом
          dispatchAreas
            .filter(du => road.id == du.roadCode)
            .map(keyDu => {
              const du_row = {
                road: keyDu.name,
                id: keyDu.id,
                treeStatus: "disabled",
                manager: road.id + "Отдел",
                roadId: keyDu.id + "Отдел2",
                selectOneRow: true,
                mainRoadId: road.id,
                hideIcon: true
              };
              let du =
                roadData &&
                roadData.length &&
                roadData[0].dispatchAreas.filter(obj => obj.id === keyDu.id);
              // тут берем данные из indicatorOrgStruct и подставляем в табличку
              indicators.map(item => {
                du_row[item.id] =
                  du &&
                  du.length > 0 &&
                  du[0].bindings &&
                  du[0].bindings.indexOf(item.id) != -1;
              });
              this.rows.push(du_row);
            });

          this.columnsArray.forEach(column => {
            let tu = this.rows.filter(row => row.manager == road.id + "Подразделение");
            let du = this.rows.filter(row => row.manager == road.id + "Отдел");

            this.rows
              .filter(row => row.roadId == road.id + "Подразделение")
              .map(
                row =>
                  (row[column.name] = tu.some(row => row[column.name] === true))
              );
            this.rows
              .filter(row => row.roadId == road.id + "Отдел")
              .map(
                row =>
                  (row[column.name] = du.some(row => row[column.name] === true))
              );
          });
        });

        this.loading = false;
        this.mainLoading = false;
        this.rows = [...this.rows];
        this.old_row = JSON.parse(JSON.stringify(this.rows));
      }
    );
  }

  public PageSelectionChange(value, rowCount) {
    this.currentPageLimit = value;
    if (value >= rowCount) {
      this.pageCondition = true;
    } else {
      this.pageCondition = false;
    }
  }

  updateRowFilter(event) {
    const val = event.target.value.toLowerCase();
    let emptyRoadIds = [];
    const filteredRow = this.old_row.filter(item => {
      if (item.road.toLowerCase().indexOf(val) !== -1 || !val) {
        return true;
      }
    });
    this.rows = JSON.parse(JSON.stringify(filteredRow));
    this.rows.forEach(item => {
      const tu_du = this.old_row.filter(el => el.roadId === item.manager)[0];
      const currentTUId = item.id + 'Подразделение';
      const currentDUId = item.id + 'Отдел';
      // если в уже добавленных строках нету Ду/Ту этого уровня, то добавляем его
      if (
        tu_du &&
        this.rows.filter(el => el.roadId === tu_du.roadId).length === 0
      ) {
        // добавляем Ту/Ду
        this.rows.push(tu_du);

        // добавляем основную дорогу
        const mainRoad = this.old_row.filter(
          el => el.roadId === tu_du.manager
        )[0];
        if (
          mainRoad &&
          this.rows.filter(el => el.roadId === tu_du.manager).length === 0
        ) {
          this.rows.push(mainRoad);
        }
      }
      if (!item.manager) {
        if (this.rows.filter(el => el.manager &&
          (el.manager === item.roadId || el.manager === currentTUId || el.manager === currentDUId)).length === 0) {
          emptyRoadIds.push(item.roadId);
        }
      }
    });
    if (emptyRoadIds.length) {
      emptyRoadIds.forEach(item => {
        this.rows[this.rows.indexOf(this.rows.slice().filter(el => el.roadId === item)[0])].treeStatus = 'disabled';
      });
    }
    // при фильтрации переходим на 1 страницу
    this.table.offset = 0;
  }

  updateColumnFilter(event) {
    let val = event.target.value.toLowerCase();
    this.columnsArray = this.oldColumns.filter(item => {
      if (item.title.toLowerCase().indexOf(val) !== -1 || !val) {
        return true;
      }
    });
    this.table.offset = 0;
  }

  //todo посмотреть под фильтром, раскрывание дерева
  onTreeAction(event: any) {
    const row = event.row;
    if (row.treeStatus === "collapsed") {
      row.treeStatus = "expanded";
    } else {
      row.treeStatus = "collapsed";
    }
    this.rows = [...this.rows];
  }

  selectAllRow(roadId, columnName) {
    let new_value;
    this.rows
      .filter(row => row.roadId == roadId)
      .map(selectedRow => {
        new_value = !selectedRow[columnName];
        selectedRow[columnName] = !selectedRow[columnName];
      });
    this.rows
      .filter(row => row.mainRoadId == roadId)
      .map(selectedRow => {
        selectedRow[columnName] = new_value;
      });
    this.rows
      .filter(row => row.manager == roadId)
      .map(selectedRow => {
        selectedRow[columnName] = new_value;
      });
    this.rows = [...this.rows];
    this.old_row
      .filter(row => row.roadId == roadId)
      .map(selectedRow => {
        selectedRow[columnName] = new_value;
      });
    this.old_row
      .filter(row => row.mainRoadId == roadId)
      .map(selectedRow => {
        selectedRow[columnName] = new_value;
      });
    this.old_row
      .filter(row => row.manager == roadId)
      .map(selectedRow => {
        selectedRow[columnName] = new_value;
      });
  }

  selectAllPartRow(roadId, columnName, mainRoadId) {
    // нажатие на заголовок ту/ду
    let new_value;
    this.rows
      .filter(row => row.roadId == roadId)
      .map(selectedRow => {
        new_value = !selectedRow[columnName];
        selectedRow[columnName] = new_value;
      }); // галка на заголовке
    this.rows
      .filter(row => row.manager == roadId)
      .map(selectedRow => {
        selectedRow[columnName] = new_value;
      }); // галки на вложенных
    this.old_row
      .filter(row => row.roadId == roadId)
      .map(selectedRow => {
        selectedRow[columnName] = new_value;
      });
    this.old_row
      .filter(row => row.manager == roadId)
      .map(selectedRow => {
        selectedRow[columnName] = new_value;
      });
    if (new_value) {
      // галка на первый уровень
      this.rows
        .filter(row => row.roadId === mainRoadId)
        .map(mainRoadRow => {
          mainRoadRow[columnName] = true;
        });
      this.old_row
        .filter(row => row.roadId === mainRoadId)
        .map(mainRoadRow => {
          mainRoadRow[columnName] = true;
        });
    } else {
      // if ( //не убирать галку с главной дороги
      //   !this.rows
      //     .filter(row => row.manager === mainRoadId)
      //     .some(row => row[columnName] === true)
      // ) {
      //   this.rows
      //     .filter(row => row.roadId === mainRoadId)
      //     .map(row => {
      //       row[columnName] = false;
      //     });
      // }
    }
  }

  selectOneRow(roadId, columnName, manager, mainRoadId) {
    const _this = this;
    this.rows
      .filter(row => row.roadId == roadId)
      .map(selectedRow => {
        selectedRow[columnName] = !selectedRow[columnName];
        // если ставим галочку на ту/ду, то ставим галочку и на дорогу
        if (selectedRow[columnName]) {
          _this.rows
            .filter(row => row.roadId == mainRoadId)
            .map(mainRoadRow => {
              mainRoadRow[columnName] = true;
            });
          _this.rows
            .filter(row => row.roadId == manager)
            .map(mainRoadRow => {
              mainRoadRow[columnName] = true;
            });
        } else {
          if (
            !_this.rows
              .filter(row => row.manager == manager)
              .some(row => row[columnName] === true)
          ) {
            _this.rows
              .filter(row => row.roadId == manager)
              .map(row => {
                row[columnName] = false;
              });
          }

          // if ( //не убирать галку с главной дороги
          //   !_this.rows
          //     .filter(row => row.manager == mainRoadId)
          //     .some(row => row[columnName] === true)
          // ) {
          //   _this.rows
          //     .filter(row => row.roadId == mainRoadId)
          //     .map(row => {
          //       row[columnName] = false;
          //     });
          // }
        }
      });
    this.rows = [...this.rows];

    this.old_row
      .filter(row => row.roadId == roadId)
      .map(selectedRow => {
        selectedRow[columnName] = !selectedRow[columnName];
        // если ставим галочку на ту/ду, то ставим галочку и на дорогу
        if (selectedRow[columnName]) {
          _this.old_row
            .filter(row => row.roadId == mainRoadId)
            .map(mainRoadRow => {
              mainRoadRow[columnName] = true;
            });
          _this.old_row
            .filter(row => row.roadId == manager)
            .map(mainRoadRow => {
              mainRoadRow[columnName] = true;
            });
        } else {
          if (
            !_this.old_row
              .filter(row => row.manager == manager)
              .some(row => row[columnName] === true)
          ) {
            _this.old_row
              .filter(row => row.roadId == manager)
              .map(row => {
                row[columnName] = false;
              });
          }

          /*if (  не убирать галку с главной дороги
            !_this.old_row
              .filter(row => row.manager == mainRoadId)
              .some(row => row[columnName] === true)
          ) {
            _this.old_row
              .filter(row => row.roadId == mainRoadId)
              .map(row => {
                row[columnName] = false;
              });
          }*/
        }
      });
  }
  ngOnDestroy() {
    this.spinner.hide();
  }

  getRowClass(row) {
    return {
      gray: row.road === "Подразделение" || row.road === "Отдел",
      hideIcon: row.hasOwnProperty('hideIcon')
    };
  }
  getHeight(row) {
    return row.road === "Подразделение" || row.road === "Отдел" ? 0 : 30;
  }

  saveData() {
    this.savingData = true;
    let result = [];
    let columnsArray = this.oldColumns;
    let old_row = this.old_row;
    old_row.map(function(item) {
      if (!item.manager) {
        let indicators = columnsArray
          .filter(obj => item[obj.name])
          .map(obj => obj.name);
        let territorialAdministrations = [];
        let dispatchAreas = [];
        old_row.map(function(itemChild) {
          if (itemChild.mainRoadId === item.roadId) {
            let res = {
              bindings: columnsArray
                .filter(obj => itemChild[obj.name])
                .map(obj => obj.name),
              id: itemChild.id,
              name: itemChild.road
            };
            if (itemChild.manager === item.roadId + "Подразделение") {
              territorialAdministrations.push(res);
            } else {
              dispatchAreas.push(res);
            }
          }
        });
        result.push({
          roadId: item.roadId,
          roadName: item.road,
          indicators: indicators,
          dispatchAreas: dispatchAreas,
          territorialAdministrations: territorialAdministrations
        });
      }
    });
    this._service.postData("/indicatorOrgStruct", result).subscribe(result => {
      this.savingData = false;
      if (result !== "error") {
        this.toastr.success(
          "Привязки показателей к оргструктуре успешно сохранены",
          "",
          { enableHtml: true, timeOut: 5000 }
        );
      }
    });
  }
}
