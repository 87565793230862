import { AppService } from './app-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

import {
  debounceTime,
  delay,
  tap,
  filter,
  map,
  take,
  takeUntil
} from 'rxjs/operators';

import * as config from '../../../appsettings.json';
import { User } from '../../model/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private apiUrl = environment.apiUrl;
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  logoutObj;
  logoutObj$;

  constructor(
    private http: HttpClient,
    private router: Router,
    private appService: AppService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(null);
    this.currentUser = this.currentUserSubject.asObservable();

    this.logoutObj = new Subject(); // в него с помощью next в errorInterceptor попадают все запросы на logout
    this.logoutObj$ = this.logoutObj
      .asObservable()
      .pipe(debounceTime(500))
      .subscribe(x => this.logout()); // позволяет ограничить колличество запросов на logout при куче ответов с 401
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  loginSpnego() {
    return this.http
      .get<any>(`${this.apiUrl}/auth-check`)
      .pipe(
        map((response: User) => {
          if (response) {
            const user = {
              abilities: response.abilities,
              departments: response.departments,
              roads: response.roads
            };
            this.currentUserSubject.next(user);
            return user;
          } else {
            throw new Error('Неправильное имя пользователя или пароль');
          }
        })
      );
  }

  // tslint:disable-next-line: member-ordering

  logout() {
    this.http
      .get<any>(`${this.apiUrl}/logout`, {
        headers: new HttpHeaders({}),
        withCredentials: true,
        observe: 'response'
      })
      .subscribe(response => {
        if (response.status === 200) {
          for (const dict in this.appService.dictionaries) {
            if (this.appService.dictionaries.hasOwnProperty(dict)) {
              const elem = this.appService.dictionaries[dict];
              elem.data.length = 0;
            }
          }

          alert('Закройте окно браузера');
          this.router.navigate(['/']);
        }
      });
    this.currentUserSubject.next(null);
  }
}
