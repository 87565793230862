import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';

import {
  MatButtonModule,
  MatDatepickerModule,
  MatInputModule,
  MatSelectModule,
  MatSidenavModule,
  MatTabsModule,
  MatToolbarModule,
  MatTreeModule,
  MatCheckboxModule,
  MatIconModule, MatRadioModule
} from '@angular/material';

import { AppRoutingModule } from './app-routing.module';
import { BindingAdjustmentComponent } from './components/binding-adjustment/binding-adjustment.component';
import { BindingOrganizationalChartComponent } from './components/binding-organizational-chart/binding-organizational-chart.component';
import { BindingIndicatorComponent } from './components/binding-indicators/binding-indicators.component';
import { BindingInputComponent } from './components/binding-input/binding-input.component';
import { BindingInputHistoryComponent } from './components/binding-input-history/binding-input-history.component';
import { EmptyComponent } from './empty';
import { AppComponent } from './app.component';
import {
  ErrorInterceptor,
  WithCredentialsInterceptor,
  AuthGuard,
  firstAbilityGuard,
  secondAbilityGuard
} from './services';
import { HomeComponent } from './components/home/home.component';
import { AccountComponent } from './components/account/account.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { ResponsibilityComponent } from './components/responsibility/responsibility.component';
import { ApprovementComponent } from './components/approvement/approvement.component';
import { CustomDPHeaderComponent } from './components/custom-dpheader/custom-dpheader.component';
import { EmptyRowDirective } from './components/binding-input-history/empty-row.directive';
import { PersonalPageComponent } from './components/personalPage/personalPage.component';

@NgModule({
  declarations: [
    AppComponent,
    BindingAdjustmentComponent,
    BindingOrganizationalChartComponent,
    BindingIndicatorComponent,
    BindingInputComponent,
    BindingInputHistoryComponent,
    EmptyComponent,
    HomeComponent,
    AccountComponent,
    AnalyticsComponent,
    ApprovementComponent,
    ResponsibilityComponent,
    PersonalPageComponent,
    CustomDPHeaderComponent,
    EmptyRowDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxDatatableModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatSidenavModule,
    MatSelectModule,
    MatTabsModule,
    MatTreeModule,
    MatIconModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatToolbarModule,
    MatCheckboxModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    MatRadioModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: WithCredentialsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    [AuthGuard, firstAbilityGuard, secondAbilityGuard]
  ],
  bootstrap: [AppComponent],
  entryComponents: [CustomDPHeaderComponent]
})
export class AppModule {}
