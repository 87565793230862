import { Injectable } from "@angular/core";
import { Observable, Subscription } from "rxjs";
// import {Http, RequestOptions} from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import "rxjs/add/observable/fromPromise";
import { ToastrService } from "ngx-toastr";

import { environment } from "src/environments/environment";

import * as mock from "../components/binding-organizational-chart/test.json";
import * as settings from "../../../appsettings.json";

@Injectable({
  providedIn: "root"
})
export class AppService {
  dictionaries = {
    dataTypeId: {
      link: "/rrw/dataType",
      data: []
    },
    dateTypeId: {
      link: "/rrw/dateType",
      data: []
    },
    departmentId: {
      link: "/rrw/department",
      data: []
    },
    metricTypeId: {
      link: "/rrw/metricType",
      data: []
    },
    indicatorId: {
      link: "/rrw/indicator",
      data: []
    },
    valueTypeId: {
      link: "/rrw/valueType",
      data: []
    },
    unitTypeId: {
      link: "/rrw/unitType",
      data: []
    },
    roadId: {
      link: "/rrw/road",
      data: []
    },
    territorialAdministrationId: {
      link: "/rrw/territorialAdministration",
      data: []
    },
    dispatchAreaId: {
      link: "/rrw/dispatchArea",
      data: []
    }
  };
  dictionariesDB = {
    data_type_id: {
      link: "/rrw/dataType",
      data: []
    },
    date_type_id: {
      link: "/rrw/dateType",
      data: []
    },
    department_id: {
      link: "/rrw/department",
      data: []
    },
    metric_type_id: {
      link: "/rrw/metricType",
      data: []
    },
    indicator_id: {
      link: "/rrw/indicator",
      data: []
    },
    value_type_id: {
      link: "/rrw/valueType",
      data: []
    },
    unit_id: {
      link: "/rrw/unitType",
      data: []
    },
    road_id: {
      link: "/rrw/road",
      data: []
    },
    territorial_administration_id: {
      link: "/rrw/territorialAdministration",
      data: []
    },
    dispatch_area_id: {
      link: "/rrw/dispatchArea",
      data: []
    }
  };
  subscriptions: Subscription = new Subscription();

  private apiUrl = environment.apiUrl;
  private dbApiUrl = '/luxmsbi/api/db/';
  private ApiUrlLuxms = '/luxmsbi/api/';

  constructor(private _http: HttpClient, private toastr: ToastrService) {}

  showError(response) {
    this.toastr.error("Ошибка сервера. Обратитесь к администратору.", "", {
      enableHtml: true,
      timeOut: 5000
    });
  }
  // Метод для получения словаря по имени, если уже загружен, берется из переменной, если первый раз
  // то запрашиваем с сервера
  getDictionary(name): Observable<any> {
    let promise = new Promise<any>((resolve, reject) => {
      if (!this.dictionaries[name].data.length) {
        this.subscriptions.add(
          this._http.get(this.apiUrl + this.dictionaries[name].link).subscribe(
            data => {
              try {
                if (data != JSON.stringify({})) {
                  this.dictionaries[name].data = data;
                  resolve(this.dictionaries[name].data);
                }
              } catch (ex) {
                this.showError(ex);
                reject(ex);
              }
            },
            error => {
              if (error === "unauthorized") {
                resolve([]);
              } else {
                this.showError(error);
                reject(error);
              }
            }
          )
        );
      } else {
        resolve(this.dictionaries[name].data);
      }
    });
    return Observable.fromPromise(promise);
  }

  // Метод для получения словаря по имени, если уже загружен, берется из переменной, если первый раз
  // то запрашиваем с сервера
  getDictionaryDBAPI(name): Observable<any> {
    let promise = new Promise<any>((resolve, reject) => {
      if (!this.dictionariesDB[name].data.length) {
        this.subscriptions.add(
          this._http.get(this.apiUrl + this.dictionariesDB[name].link).subscribe(
            data => {
              try {
                if (data != JSON.stringify({})) {
                  this.dictionariesDB[name].data = data;
                  resolve(this.dictionariesDB[name].data);
                }
              } catch (ex) {
                this.showError(ex);
                reject(ex);
              }
            },
            error => {
              if (error === "unauthorized") {
                resolve([]);
              } else {
                this.showError(error);
                reject(error);
              }
            }
          )
        );
      } else {
        resolve(this.dictionariesDB[name].data);
      }
    });
    return Observable.fromPromise(promise);
  }

  getMockDictionary(name): Observable<any> {
    let promise = new Promise<any>((resolve, reject) => {
      if (!this.dictionaries[name].data.length) {
        try {
          resolve(this.dictionaries[name].data);
        } catch (ex) {
          reject(ex);
        }
      } else {
        resolve(this.dictionaries[name].data);
      }
    });
    return Observable.fromPromise(promise);
  }

  getMockData(link: string): Observable<any> {
    let promise = new Promise<any>((resolve, reject) => {
      try {
        resolve(mock.orgStructure);
      } catch (ex) {
        reject(ex);
      }
    });
    return Observable.fromPromise(promise);
  }

  // Метод для гет запроса, запрашивает ссылку без хоста
  // например /performanceMeasurement
  getData(link: string, params?: any): Observable<any> {
    let promise = new Promise<any>((resolve, reject) => {
      this.subscriptions.add(
        this._http
          .get(this.apiUrl + link, params ? { params: params } : {})
          .subscribe(
            data => {
              try {
                if (data != JSON.stringify({})) {
                  resolve(data);
                }
              } catch (ex) {
                this.showError(ex);
                reject(ex);
              }
            },
            error => {
              if (error === "unauthorized") {
                resolve([]);
              } else {
                this.showError(error);
                reject(error);
              }
            }
          )
      );
    });
    return Observable.fromPromise(promise);
  }

  // Метод для пост запроса, запрашивает ссылку без хоста
  // например /performanceMeasurement
  getDataBody(link: string, params?: any): Observable<any> {
    let promise = new Promise<any>((resolve, reject) => {
      this.subscriptions.add(
        this._http.get(this.apiUrl + link, { params: params }).subscribe(
          data => {
            try {
              if (data != JSON.stringify({})) {
                resolve(data);
              }
            } catch (ex) {
              this.showError(ex);
              reject(ex);
            }
          },
          error => {
            if (error === "unauthorized") {
              resolve([]);
            } else {
              this.showError(error);
              reject(error);
            }
          }
        )
      );
    });
    return Observable.fromPromise(promise);
  }

  // Метод для get запроса, запрашивает ссылку без хоста
  // например /performanceMeasurement
  getDataLuxms(link: string, params?: any): Observable<any> {
    let promise = new Promise<any>((resolve, reject) => {
      this.subscriptions.add(
        this._http.get(this.ApiUrlLuxms + link, { params: params }).subscribe(
          data => {
            try {
              if (data != JSON.stringify({})) {
                resolve(data);
              }
            } catch (ex) {
              this.showError(ex);
              reject(ex);
            }
          },
          error => {
            if (error === "unauthorized") {
              resolve([]);
            } else {
              this.showError(error);
              reject(error);
            }
          }
        )
      );
    });
    return Observable.fromPromise(promise);
  }
  getDataBodyDBAPI(link: string, params?: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    if (Object.keys(params).length) {
      link += "/.filter(report_date='" + params.report_date +
        "'&&date_type_id=" + params.date_type_id + "&&department_id=" + params.department_id + ")";
    }
    let promise = new Promise<any>((resolve, reject) => {
      this.subscriptions.add(
        this._http.get(this.dbApiUrl + link, { headers }).subscribe(
          data => {
            try {
              if (data != JSON.stringify({})) {
                resolve(data);
              }
            } catch (ex) {
              this.showError(ex);
              reject(ex);
            }
          },
          error => {
            if (error === "unauthorized") {
              resolve([]);
            } else {
              this.showError(error);
              reject(error);
            }
          }
        )
      );
    });
    return Observable.fromPromise(promise);
  }

  // Метод удаление
  removeDataDBAPI(link: string, id: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let promise = new Promise<any>((resolve, reject) => {
      this.subscriptions.add(
        this._http.delete(this.dbApiUrl + link, { headers, params: { id } }).subscribe(
          data => {
            try {
              resolve(data);
            } catch (ex) {
              this.showError(ex);
              reject(ex);
            }
          },
          error => {
            if (error === "unauthorized") {
              resolve([]);
            } else {
              this.showError(error);
              reject(error);
            }
          }
        )
      );
    });
    return Observable.fromPromise(promise);
  }

  // Метод удаление
  removeData(link: string, id: string): Observable<any> {
    let promise = new Promise<any>((resolve, reject) => {
      this.subscriptions.add(
        this._http.delete(this.apiUrl + link, { params: { id: id } }).subscribe(
          data => {
            try {
              resolve(data);
            } catch (ex) {
              this.showError(ex);
              reject(ex);
            }
          },
          error => {
            if (error === "unauthorized") {
              resolve([]);
            } else {
              this.showError(error);
              reject(error);
            }
          }
        )
      );
    });
    return Observable.fromPromise(promise);
  }

  // Метод patch запроса, запрашивает ссылку без хоста
  // например /performanceMeasurement и данные
  patchDataDBAPI(link: string, data: any, options?): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let promise = new Promise<any>((resolve, reject) => {
      this.subscriptions.add(
        this._http.patch(this.dbApiUrl + link, data, {headers} ).subscribe(
          data => {
            try {
              resolve(data);
            } catch (ex) {
              this.showError(ex);
              reject(ex);
            }
          },
          error => {
            if (error === "unauthorized") {
              resolve([]);
            } else {
              this.showError(error);
              reject(error);
            }
          }
        )
      );
    });
    return Observable.fromPromise(promise);
  }

  // Метод put запроса, запрашивает ссылку без хоста
  // например /performanceMeasurement и данные
  putDataDBAPI(link: string, data?: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let promise = new Promise<any>((resolve, reject) => {
      this.subscriptions.add(
        this._http.put(this.dbApiUrl + link, data ? data : null, {headers}).subscribe(
          data => {
            try {
              resolve(data);
            } catch (ex) {
              this.showError(ex);
              reject(ex);
            }
          },
          error => {
            if (error === "unauthorized") {
              resolve([]);
            } else {
              this.showError(error);
              reject(error);
            }
          }
        )
      );
    });
    return Observable.fromPromise(promise);
  }
  // Метод post запроса, запрашивает ссылку без хоста
  // например /performanceMeasurement и данные
  postDataDBAPI(link: string, data?: any): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let promise = new Promise<any>((resolve, reject) => {
      this.subscriptions.add(
        this._http.post(this.dbApiUrl + link, data ? data : null, {headers}).subscribe(
          data => {
            try {
              resolve(data);
            } catch (ex) {
              this.showError(ex);
              reject(ex);
            }
          },
          error => {
            if (error === "unauthorized") {
              resolve([]);
            } else {
              this.showError(error);
              reject(error);
            }
          }
        )
      );
    });
    return Observable.fromPromise(promise);
  }

  // Метод post запроса, запрашивает ссылку без хоста
  // например /performanceMeasurement и данные
  postData(link: string, data?: any): Observable<any> {
    let promise = new Promise<any>((resolve, reject) => {
      this.subscriptions.add(
        this._http.post(this.apiUrl + link, data ? data : null).subscribe(
          data => {
            try {
              resolve(data);
            } catch (ex) {
              this.showError(ex);
              reject(ex);
            }
          },
          error => {
            if (error === "unauthorized") {
              resolve([]);
            } else {
              this.showError(error);
              reject(error);
            }
          }
        )
      );
    });
    return Observable.fromPromise(promise);
  }

  //custom header datapicker methods

  private customPeriod = "Месяц";

  setCustomPeriod(period: string): void {
    this.customPeriod = period;
  }

  getCustomPeriod() {
    return this.customPeriod;
  }

  destroySubscriptions() {
    // this.subscriptions.unsubscribe(); // успеет отписаться от остальных запросов и не вывалить ошибки до перезагрузки
  }
}
